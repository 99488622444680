import React, { useState } from "react";
import { useSelector } from "react-redux";

import OrderListView from "./OrderList.view";
import HeaderComponent from "../Header/Header";
import FooterComponent from "../Footer/Footer";
const OrderListComponent = ({
  status,
  orders,
  fetchOrders,
  statusData,
  loader,
  fetchOrdersCsv,
  fetchProductsCsv,
  fetchAdminOrderCsv
}) => {
  const loginData = useSelector((state) => state.applicationReducer.login);

  const returnLoginData = (data) => {
    if (data === undefined) {
      return JSON.parse(localStorage.getItem("user"));
    } else {
      return data.data;
    }
  };

  const onCallPagination = (page) => {
    fetchOrders({ status: statusData, page: page }, 1);
  };

  return (
    <>
      <HeaderComponent />
      <OrderListView
        status={status}
        orders={orders}
        statusData={statusData}
        fetchOrders={fetchOrders}
        loader={loader}
        loginData={returnLoginData(loginData)}
        onCallPagination={onCallPagination}
        fetchOrdersCsv={fetchOrdersCsv}
        fetchProductsCsv={fetchProductsCsv}
        fetchAdminOrderCsv={fetchAdminOrderCsv}
      />

      <FooterComponent />
    </>
  );
};
export default OrderListComponent;
