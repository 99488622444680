import React from "react";
import history from "../../history";
import LoaderComponent from "./../Loader/Loader";
import { formatter, getValue } from "../../utils/commonUtils";
import PaginationComponent from "../Pagination";

const OrderListView = ({
  status,
  orders,
  fetchOrders,
  statusData,
  loader,
  loginData,
  onCallPagination,
  fetchOrdersCsv,
  fetchProductsCsv,
  fetchAdminOrderCsv,
}) => {
  return (
    <div>
      <div className="react-bosch-loader">{loader && <LoaderComponent />}</div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-6">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Ordini</h4>
                </div>
              </div>
              <div className="col-6 d-flex justify-content-end">
                <button
                  className="btn btn-green mr-2"
                  onClick={() => fetchOrdersCsv()}
                >
                  Scarica ordini
                </button>
                {loginData &&
                  loginData.roles &&
                  loginData.roles.includes("administrator") && (
                    <>
                      <button
                        className="btn btn-green mr-2"
                        onClick={() => fetchAdminOrderCsv()}
                      >
                        File ordini per gestionale
                      </button>
                      <button
                        className="btn btn-red"
                        onClick={() => fetchProductsCsv()}
                      >
                        Scarica giacenze
                      </button>
                    </>
                  )}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body  pt-0">
                    <ul className="nav nav-tabs nav-tabs-custom mb-4">
                      {status &&
                        status.data &&
                        status.data.length > 0 &&
                        status.data.map((data) => {
                          return (
                            <li className="nav-item">
                              <a
                                className={
                                  statusData === data.id
                                    ? "nav-link p-3 active"
                                    : "nav-link p-3"
                                }
                                onClick={(e) =>
                                  fetchOrders({ status: data.id }, 1)
                                }
                              >
                                {data.label}
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                    <div className="table-responsive">
                      <table className="table table-centered datatable dt-responsive nowrap ">
                        <thead className="thead-light">
                          <tr>
                            <th>N° Ordine</th>
                            <th>Data d'ordine</th>
                            <th>Nome e Cognome</th>
                            <th>Totale ordine</th>
                            {loginData &&
                              loginData.roles &&
                              (loginData.roles.includes("administrator") || loginData.roles.includes("shop")) && (
                                <th>Punto vendita selezionata</th>
                              )}
                            <th>Stato</th>
                            {loginData &&
                              loginData.roles &&
                              loginData.roles.includes("administrator") && (
                                <th>Provenienza lead</th>
                              )}
                            <th>Azioni</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orders &&
                            orders.data &&
                            orders.data.order &&
                            orders.data.order.length > 0 ? (
                            orders.data.order.map((order) => {
                              return (
                                <tr>
                                  <td>
                                    <a
                                      href="javascript: void(0);"
                                      className="text-dark font-weight-bold"
                                    >
                                      #{order.id}
                                    </a>{" "}
                                  </td>
                                  <td>{order.date}</td>
                                  <td>{order.name}</td>

                                  <td>{formatter(order.total, 2)} + IVA</td>
                                  {loginData &&
                                    loginData.roles &&
                                    (loginData.roles.includes("administrator") || loginData.roles.includes("shop")) && (
                                      <td>
                                        {getValue(order, "order.dealer.company_name")}
                                      </td>
                                    )}
                                  <td>
                                    <div
                                      className={
                                        order.status.id === "wc-processing"
                                          ? "badge badge-soft-success font-size-12"
                                          : order.status.id === "wc-cancelled"
                                            ? "badge badge-soft-danger font-size-12"
                                            : "badge badge-soft-warning font-size-12"
                                      }
                                    >
                                      {order.status.label}
                                    </div>
                                  </td>
                                  {loginData &&
                                    loginData.roles &&
                                    loginData.roles.includes("administrator") && (
                                      <td>
                                        <div
                                          className={
                                            order.order_from_dealer_page === "0"
                                              ? "badge badge-soft-bosch font-size-12"
                                              : "badge badge-soft-dealer font-size-12"
                                          }
                                        >
                                          {order.order_from_dealer_page === "0"
                                            ? "Bosch"
                                            : "Dealer"}
                                        </div>
                                      </td>
                                    )}
                                  <td>
                                    <a>
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-no-rounded btn-no-border waves-effect waves-light"
                                        onClick={(e) =>
                                          history.push(
                                            "/orders/edit/" + order.id
                                          )
                                        }
                                      >

                                        {loginData &&
                                          loginData.roles &&
                                          !loginData.roles.includes("administrator") &&
                                          order.status.id === "wc-on-hold"

                                          ? <div><i class="mr-2 fas c'à posta per te fa-edit"></i><span>Aggiorna</span></div>
                                          : <span>Dettaglio</span>
                                        }

                                      </button>
                                    </a>
                                  </td>
                                </tr>

                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="8">
                                <center>Nessun ordine ricevuto</center>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {getValue(orders, "orders.data.total_page") > 1 && (
                        <PaginationComponent
                          data={orders.data}
                          onClickApi={onCallPagination}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderListView;
