export const schema = {
  FETCH_USER_DETAILS: ({ userId }) => {
    let url = `https://yourappapi.com/users/${userId}`;
    return {
      props: "userDetails",
      url,
      method: "GET",
    };
  },
  UPDATE_USER_DETAILS: () => {
    let url = `https://yourappapi.com/users`;
    return {
      props: "userDetails",
      url,
      method: "PUT",
    };
  },
  ADD_USER_DETAILS: () => {
    let url = `https://yourappapi.com/users`;
    return {
      props: "userDetails",
      url,
      method: "post",
    };
  },
  LOGIN_USER: (payload) => {
    // let url = `https://donki-bosch-api.mahiz.dev/api/bosch/v1/user/token`;
    let url = `${process.env.REACT_APP_API_URL}/api/bosch/v1/user/token`;
    return {
      props: "login",
      url,
      method: "post",
      payload: payload,
    };
  },
  RESET_USER_PASSWORD: (payload) => {
    //let url = `https://donki-bosch-api.mahiz.dev/api/bosch/v1/user/reset_password`;
    let url = `${process.env.REACT_APP_API_URL}/api/bosch/v1/user/reset_password`;
    return {
      props: "resetPassword",
      url,
      method: "post",
      payload: payload,
    };
  },
  SET_USER_PASSWORD: (payload) => {
    //let url = `https://donki-bosch-api.mahiz.dev/api/bosch/v1/user/update_password`;
    let url = `${process.env.REACT_APP_API_URL}/api/bosch/v1/user/update_password`;
    return {
      props: "setPassword",
      url,
      method: "post",
      payload: payload,
    };
  },
  FETCH_ORDER_LIST: (payload) => {
    let pageNumber = payload.page || 1;
    //let url = `https://donki-bosch-api.mahiz.dev/api/bosch/v1/db/orders?status=${payload.status}&page=1&size=10`;
    let url = `${process.env.REACT_APP_API_URL}/api/bosch/v1/db/orders?status=${payload.status}&page=${pageNumber}&size=3`;
    return {
      props: "orderList",
      url,
      method: "get",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
    };
  },
  FETCH_ORDER_CATEGORY_LIST: () => {
    //let url = "https://donki-bosch-api.mahiz.dev/api/bosch/v1/db/status";
    let url = `${process.env.REACT_APP_API_URL}/api/bosch/v1/db/status`;
    return {
      props: "categoryList",
      url,
      method: "get",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
    };
  },
  FETCH_ORDER_DETAIL: (payload) => {
    console.log("payload", payload);
    //let url = `https://donki-bosch-api.mahiz.dev/api/bosch/v1/db/orders/${payload.id}`;
    let url = `${process.env.REACT_APP_API_URL}/api/bosch/v1/db/orders/${payload.id}`;
    return {
      props: "orderDetail",
      url,
      method: "get",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
    };
  },

  UPDATE_ORDER: (payload) => {
    //let url = `https://donki-bosch-api.mahiz.dev/api/bosch/v1/db/orders/${payload.id}`;
    let url = `${process.env.REACT_APP_API_URL}/api/bosch/v1/db/orders/${payload.id}`;
    return {
      props: "orderCreate",
      url,
      method: "post",
      payload: payload,
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
    };
  },
  FETCH_DASHBOARD_DATA: () => {
    //let url = `https://donki-bosch-api.mahiz.dev/api/bosch/v1/db/orders/${payload.id}`;
    let url = `${process.env.REACT_APP_API_URL}/api/bosch/v1/stats/all`;
    return {
      props: "dashboardData",
      url,
      method: "get",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
    };
  },
  FETCH_ORDERS_CSV: () => {
    //let url = `https://donki-bosch-api.mahiz.dev/api/bosch/v1/db/orders?status=${payload.status}&page=1&size=10`;
    let url = `${process.env.REACT_APP_API_URL}/api/bosch/v1/stats/order`;
    return {
      props: "orderCsv",
      url,
      method: "get",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
    };
  },
  FETCH_PRODUCTS_CSV: () => {
    //let url = `https://donki-bosch-api.mahiz.dev/api/bosch/v1/db/orders?status=${payload.status}&page=1&size=10`;
    let url = `${process.env.REACT_APP_API_URL}/api/bosch/v1/stats/product`;
    return {
      props: "productCsv",
      url,
      method: "get",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
    };
  },

  FETCH_ADMIN_ORDER_CSV: () => {
    //let url = `https://donki-bosch-api.mahiz.dev/api/bosch/v1/db/orders?status=${payload.status}&page=1&size=10`;
    let url = `${process.env.REACT_APP_API_URL}/api/bosch/v1/stats/order-admin`;
    return {
      props: "adminOrderCsv",
      url,
      method: "get",
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
    };
  },

  
};
