import React from "react";
// import {
//   NUMBER_VALIDATION_REGEX,
//   EMAIL_VALIDATION_REGEX,
//   PHONE_NUMBER_WITH_COUNTRY_CODE_VALIDATION_REGEX,
// } from "../constants/validation";
export const getValue = (obj, expression) => {
  try {
    return expression.split(".").reduce((o, x, i) => {
      if (i === 0) {
        return o;
      }
      return typeof o === "undefined" || o === null ? undefined : o[x];
    }, obj);
  } catch (e) {
    console.error("Failed to getValue => ", e);
    return undefined;
  }
};

export const formatter = (value, minimumFractionDigits) =>
  new Intl.NumberFormat("it-IT", {
    style: "currency",
    minimumFractionDigits: minimumFractionDigits,
    currency: "EUR",
  }).format(value);
// export const tableFieldName = [
//   "companyName",
//   "clientName",
//   "email",
//   "phoneNumber",
//   "actions",
//   "reason",
// ];
// export const tableFieldNameForDisplay = {
//   reason: "Reason",
//   companyName: "Company Name",
//   clientName: "Client Name",
//   email: "Email",
//   phoneNumber: "Phone Number",
//   actions: "Actions",
// };

// export const kybTabName = [
//   "companyProfile",
//   "aboutClient",
//   "businessModelOverView",
//   "financialBackground",
//   "dueDiligence",
// ];
// export const kybTabNameForDisplay = {
//   companyProfile: "Company Profile",
//   aboutClient: "About Client",
//   businessModalOverView: "Business Model/Overview",
//   financialBackground: "Financial Background",
//   dueDiligence: "Due Diligence",
// };
// export const companyDetailsTabName = [
//   "cardsIssued",
//   "loads",
//   "spendPurchaseTransactionVolumes",
//   "spendPurchaseValue",
//   "redemptionProfile",
// ];
// export const companyDetailsTabNameForDisplay = {
//   cardsIssued: "Cards Issued",
//   loads: "Loads (Excluding batch loads)",
//   spendPurchaseTransactionVolumes: "Spend/Purchase Transaction Volumes",
//   spendPurchaseValue: "Spend/Purchase Value",
//   redemptionProfile: "Redemption Profile",
// };
// export const otherRequirementTabName = [
//   "requirementsOverview",
//   "productIssuance",
//   "customerJourney",
//   "cardProductionRequirements",
//   "secure",
//   "kYCAndSanctions",
// ];
// export const otherRequirementTabNameForDisplay = {
//   requirementsOverview: "Requirements Overview",
//   productIssuance: "Product Issuance",
//   customerJourney: "Customer Journey",
//   cardProductionRequirements: "Card Production Requirements",
//   secure: "3D Secure",
//   kYCAndSanctions: "KYC and Sanctions",
// };
// export const sideNavBaroptions = [
//   {
//     title: <span className="kyb">KYB</span>,
//     key: "kyb",
//   },
//   {
//     title: <span className="nda">NDA</span>,
//     key: "nda",
//   },
//   {
//     title: <span className="companyDetails">Company Details</span>,
//     key: "companyDetails",
//   },
//   {
//     title: <span className="otherRequirements">Other Requirements</span>,
//     key: "otherRequirements",
//   },
// ];

// export const processNameForAPI = {
//   applicationDistribution: "application-distribution",
//   isPersonalised: "is-personalised",
//   pinDistribution: "pin-distribution",
//   activationMethod: "activation-method",
//   carryCardApp: "intent-to-carry-card-app",
//   reloadable: "reloadable",
//   activationMethodForLoad: "activation-method",
//   acceptanceEnvironment: "acceptance-environment",
//   spendChannel: "spend-channels",
// };
// export const childOfStages = {
//   "card-application": ["applicationDistribution", "isPersonalised"],
//   "pin-issuance": ["pinDistribution"],
//   "card-activation": ["activationMethod", "carryCardApp"],
//   "load-channels": [
//     "reloadable",
//     "activationMethodForLoad",
//     "acceptanceEnvironment",
//     "spendChannel",
//   ],
// };
// export const arrayOfStages = [
//   "card-application",
//   "pin-issuance",
//   "load-channels",
//   "card-activation",
// ];
// export const optionsForApplicationAndDistribution = [
//   {
//     label: "Store",
//     value: "Store",
//   },
//   {
//     label: "Online",
//     value: "Online",
//   },
//   {
//     label: "Distribution Network",
//     value: "Distribution Network",
//   },
//   {
//     label: "Corporate",
//     value: "Corporate",
//   },
// ];
// export const optionsForYesAndNo = [
//   {
//     label: "Yes",
//     value: "Yes",
//   },
//   {
//     label: "No",
//     value: "No",
//   },
// ];
// export const optionsForPin = [
//   {
//     label: "APP",
//     value: "APP",
//   },
//   {
//     label: "SMS",
//     value: "SMS",
//   },
//   {
//     label: "PIN Mailer",
//     value: "PIN Mailer",
//   },
//   {
//     label: "Other",
//     value: "Other",
//   },
// ];
// export const optionsForActivationMethod = [
//   {
//     label: "APP",
//     value: "APP",
//   },
//   {
//     label: "SMS",
//     value: "SMS",
//   },
//   {
//     label: "Batch",
//     value: "Batch",
//   },
//   {
//     label: "Online",
//     value: "Online",
//   },
//   {
//     label: "Corporate",
//     value: "Corporate",
//   },
//   {
//     label: "Call centre",
//     value: "Call centre",
//   },
//   {
//     label: "Other",
//     value: "Other",
//   },
// ];
// export const optionsForActivationMethodBanking = [
//   {
//     label: "Debit",
//     value: "Debit",
//   },
//   {
//     label: "Credit",
//     value: "Credit",
//   },
//   {
//     label: "Batch",
//     value: "Batch",
//   },
//   {
//     label: "Open Banking",
//     value: "Open Banking",
//   },
//   {
//     label: "Cash",
//     value: "Cash",
//   },
//   {
//     label: "Bank Transfer",
//     value: "Bank Transfer",
//   },
// ];
// export const optionsForAcceptanceEnvironment = [
//   {
//     label: "Open Loop",
//     value: "Open Loop",
//   },
//   {
//     label: "Closed Loop",
//     value: "Closed Loop",
//   },
//   {
//     label: "Private Loop",
//     value: "Private Loop",
//   },
// ];
// export const optionsForSpendChannels = [
//   {
//     label: "ATM",
//     value: "ATM",
//   },
//   {
//     label: "POS",
//     value: "POS",
//   },
//   {
//     label: "Internet",
//     value: "Internet",
//   },
//   {
//     label: "MOTO (Mail Order & Telephone Order)",
//     value: "MOTO (Mail Order & Telephone Order)",
//   },
// ];
// export const processNameFormAPI = {
//   "application-distribution": "applicationDistribution",
//   "is-personalised": "isPersonalised",
//   "pin-distribution": "pinDistribution",
//   "activation-method": "activationMethod",
//   "intent-to-carry-card-app": "carryCardApp",
//   reloadable: "reloadable",
//   "acceptance-environment": "acceptanceEnvironment",
//   "spend-channels": "spendChannel",
// };
// export const processNameWithoutArray = [
//   "isPersonalised",
//   "carryCardApp",
//   "reloadable",
// ];
// export const countryList = [
//   {
//     label: "Austria",
//     value: "Austria",
//   },
//   {
//     label: "Belgium",
//     value: "Belgium",
//   },
//   {
//     label: "Bulgaria",
//     value: "Bulgaria",
//   },
//   {
//     label: "Croatia",
//     value: "Croatia",
//   },
//   {
//     label: "Cyprus",
//     value: "Cyprus",
//   },
//   {
//     label: "Czechia",
//     value: "Czechia",
//   },
//   {
//     label: "Denmark",
//     value: "Denmark",
//   },
//   {
//     label: "Estonia",
//     value: "Estonia",
//   },
//   {
//     label: "Finland",
//     value: "Finland",
//   },
//   {
//     label: "France",
//     value: "France",
//   },
//   {
//     label: "Germany",
//     value: "Germany",
//   },
//   {
//     label: "Greece",
//     value: "Greece",
//   },
//   {
//     label: "Hungary",
//     value: "Hungary",
//   },
//   {
//     label: "Ireland",
//     value: "Ireland",
//   },
//   {
//     label: "Italy",
//     value: "Italy",
//   },
//   {
//     label: "Latvia",
//     value: "Latvia",
//   },
//   {
//     label: "Lithuania",
//     value: "Lithuania",
//   },
//   {
//     label: "Luxembourg",
//     value: "Luxembourg",
//   },
//   {
//     label: "Malta",
//     value: "Malta",
//   },
//   {
//     label: "Netherlands",
//     value: "Netherlands",
//   },
//   {
//     label: "Poland",
//     value: "Poland",
//   },
//   {
//     label: "Portugal",
//     value: "Portugal",
//   },
//   {
//     label: "Romania",
//     value: "Romania",
//   },
//   {
//     label: "Slovakia",
//     value: "Slovakia",
//   },
//   {
//     label: "Slovenia",
//     value: "Slovenia",
//   },
//   {
//     label: "Spain",
//     value: "Spain",
//   },
//   {
//     label: "Sweden",
//     value: "Sweden",
//   },
// ];
// export const optionsForEmployeeCount = [
//   {
//     label: "0-5",
//     value: "0-5",
//   },
//   {
//     label: "6-10",
//     value: "6-10",
//   },
//   {
//     label: "10+",
//     value: "10+",
//   },
// ];
// export const optionsForNumberOfYearsTrading = [
//   {
//     label: "0-1",
//     value: "0-1",
//   },
//   {
//     label: "1-3",
//     value: "1-3",
//   },
//   {
//     label: "3+",
//     value: "3+",
//   },
// ];
// export const yesNoOption = [
//   {
//     label: <b>Yes</b>,
//     value: true,
//   },
//   {
//     label: <b>No</b>,
//     value: false,
//   },
// ];
// export const optionsForHomeMenu = [
//   {
//     title: <span className="kyb">KYB</span>,
//     key: "kyb",
//   },
//   {
//     title: <span className="nda">NDA</span>,
//     key: "nda",
//   },
//   {
//     title: <span className="companyDetails">Company details</span>,
//     key: "companyDetails",
//   },
//   {
//     title: <span className="otherRequirements">Other Requirements</span>,
//     key: "otherRequirements",
//   },
// ];

// export const optionsForProgramManagerMenu = [
//   {
//     title: <span className="processor">Processor</span>,
//     key: "processor",
//     subOption: [
//       {
//         title: <span className="clientInformation">Client Information</span>,
//         key: "clientInformation",
//       },
//       {
//         title: <span className="productInformation">Product Information</span>,
//         key: "productInformation",
//       },
//       {
//         title: <span className="rules">Rules</span>,
//         key: "rules",
//       },
//       {
//         title: <span className="tokenFlags">Token Flags</span>,
//         key: "tokenFlags",
//       },
//       {
//         title: <span className="fees">Fees</span>,
//         key: "fees",
//       },
//     ],
//   },
//   {
//     title: <span className="wireCard">WireCard</span>,
//     key: "wireCard",
//   },
// ];
// export const optionForClientDescription = [
//   {
//     label: "Retail Bank",
//     value: "Retail Bank",
//   },
//   {
//     label: "Card Manager",
//     value: "Card Manager",
//   },
//   {
//     label: "Third Party Support Community",
//     value: "Third Party Support Community",
//   },
// ];
// export const countryOfIssueList = [
//   {
//     label: "Angola",
//     value: "Angola",
//   },
//   {
//     label: "Antigua and Barbuda",
//     value: "Antigua and Barbuda",
//   },
//   {
//     label: "Argentina",
//     value: "Argentina",
//   },
//   {
//     label: "Bahamas",
//     value: "Bahamas",
//   },
//   {
//     label: "Bangladesh",
//     value: "Bangladesh",
//   },
//   {
//     label: "Belarus",
//     value: "Belarus",
//   },
//   {
//     label: "Belize",
//     value: "Belize",
//   },
//   {
//     label: "Benin",
//     value: "Benin",
//   },
//   {
//     label: "Burma (Myanmar)",
//     value: "Burma (Myanmar)",
//   },
//   {
//     label: "Burundi",
//     value: "Burundi",
//   },
//   {
//     label: "Cambodia",
//     value: "Cambodia",
//   },
//   {
//     label: "Chad",
//     value: "Chad",
//   },
//   {
//     label: "Colombia",
//     value: "Colombia",
//   },
//   {
//     label: "Comoros",
//     value: "Comoros",
//   },
//   {
//     label: "Guatemala",
//     value: "Guatemala",
//   },
//   {
//     label: "Guinea",
//     value: "Guinea",
//   },
//   {
//     label: "Haiti",
//     value: "Haiti",
//   },
//   {
//     label: "Honduras",
//     value: "Honduras",
//   },
//   {
//     label: "India",
//     value: "India",
//   },
//   {
//     label: "Indonesia",
//     value: "Indonesia",
//   },
//   {
//     label: "Iraq",
//     value: "Iraq",
//   },
//   {
//     label: "Jamaica",
//     value: "Jamaica",
//   },
//   {
//     label: "Kazakhstan",
//     value: "Kazakhstan",
//   },
//   {
//     label: "Korea, North (DPRK)",
//     value: "Korea, North (DPRK)",
//   },
//   {
//     label: "Kuwait",
//     value: "Kuwait",
//   },
//   {
//     label: "Kyrgyzstan",
//     value: "Kyrgyzstan",
//   },
//   {
//     label: "Laos",
//     value: "Laos",
//   },
//   {
//     label: "Lebanon",
//     value: "Lebanon",
//   },
//   {
//     label: "Liberia",
//     value: "Liberia",
//   },
//   {
//     label: "Liechtenstein",
//     value: "Liechtenstein",
//   },
//   {
//     label: "Macau",
//     value: "Macau",
//   },
//   {
//     label: "Madagascar",
//     value: "Madagascar",
//   },
//   {
//     label: "Maldives",
//     value: "Maldives",
//   },
//   {
//     label: "Marshall Islands",
//     value: "Marshall Islands",
//   },
//   {
//     label: "Mexico",
//     value: "Mexico",
//   },
//   {
//     label: "Moldova",
//     value: "Moldova",
//   },
//   {
//     label: "Monaco",
//     value: "Monaco",
//   },
//   {
//     label: "Mongolia",
//     value: "Mongolia",
//   },
//   {
//     label: "Mozambique",
//     value: "Mozambique",
//   },
//   {
//     label: "Namibia",
//     value: "Namibia",
//   },
//   {
//     label: "Nauru",
//     value: "Nauru",
//   },
//   {
//     label: "Nepal",
//     value: "Nepal",
//   },
//   {
//     label: "Nicaragua",
//     value: "Nicaragua",
//   },
//   {
//     label: "Nigeria",
//     value: "Nigeria",
//   },
//   {
//     label: "Pakistan",
//     value: "Pakistan",
//   },
//   {
//     label: "Palestinian Territories",
//     value: "Palestinian Territories",
//   },
//   {
//     label: "Panama",
//     value: "Panama",
//   },
//   {
//     label: "Peru",
//     value: "Peru",
//   },
//   {
//     label: "Russia",
//     value: "Russia",
//   },
//   {
//     label: "Saint Kitts and Nevis",
//     value: "Saint Kitts and Nevis",
//   },
//   {
//     label: "Saudi Arabia",
//     value: "Saudi Arabia",
//   },
//   {
//     label: "Serbia",
//     value: "Serbia",
//   },
//   {
//     label: "Seychelles",
//     value: "Seychelles",
//   },
//   {
//     label: "Sri Lanka",
//     value: "Sri Lanka",
//   },
//   {
//     label: "Tajikistan",
//     value: "Tajikistan",
//   },
//   {
//     label: "Tanzania",
//     value: "Tanzania",
//   },
//   {
//     label: "Trinidad and Tobago",
//     value: "Trinidad and Tobago",
//   },
//   {
//     label: "Tunisia",
//     value: "Tunisia",
//   },
//   {
//     label: "Turkey",
//     value: "Turkey",
//   },
//   {
//     label: "Uganda",
//     value: "Uganda",
//   },
//   {
//     label: "Ukraine",
//     value: "Ukraine",
//   },
//   {
//     label: "United Arab Emirates",
//     value: "United Arab Emirates",
//   },
//   {
//     label: "Uzbekistan",
//     value: "Uzbekistan",
//   },
//   {
//     label: "Venezuela",
//     value: "Venezuela",
//   },
//   {
//     label: "Vietnam",
//     value: "Vietnam",
//   },
//   {
//     label: "Zimbabwe",
//     value: "Zimbabwe",
//   },
// ];
// export const currency = [
//   { label: "AED", value: "AED" },
//   { label: "AFN", value: "AFN" },
//   { label: "ALL", value: "ALL" },
//   { label: "AMD", value: "AMD" },
//   { label: "ANG", value: "ANG" },
//   { label: "AOA", value: "AOA" },
//   { label: "ARS", value: "ARS" },
//   { label: "AUD", value: "AUD" },
//   { label: "AWG", value: "AWG" },
//   { label: "AZN", value: "AZN" },
//   { label: "BAM", value: "BAM" },
//   { label: "BBD", value: "BBD" },
//   { label: "BDT", value: "BDT" },
//   { label: "BGN", value: "BGN" },
//   { label: "BHD", value: "BHD" },
//   { label: "BIF", value: "BIF" },
//   { label: "BMD", value: "BMD" },
//   { label: "BND", value: "BND" },
//   { label: "BOB", value: "BOB" },
//   { label: "BOV", value: "BOV" },
//   { label: "BRL", value: "BRL" },
//   { label: "BSD", value: "BSD" },
//   { label: "BTN", value: "BTN" },
//   { label: "BWP", value: "BWP" },
//   { label: "BYN", value: "BYN" },
//   { label: "BZD", value: "BZD" },
//   { label: "CAD", value: "CAD" },
//   { label: "CDF", value: "CDF" },
//   { label: "CHE", value: "CHE" },
//   { label: "CHF", value: "CHF" },
//   { label: "CHW", value: "CHW" },
//   { label: "CLF", value: "CLF" },
//   { label: "CLP", value: "CLP" },
//   { label: "CNY", value: "CNY" },
//   { label: "COP", value: "COP" },
//   { label: "COU", value: "COU" },
//   { label: "CRC", value: "CRC" },
//   { label: "CUC", value: "CUC" },
//   { label: "CUP", value: "CUP" },
//   { label: "CVE", value: "CVE" },
//   { label: "CZK", value: "CZK" },
//   { label: "DJF", value: "DJF" },
//   { label: "DKK", value: "DKK" },
//   { label: "DOP", value: "DOP" },
//   { label: "DZD", value: "DZD" },
//   { label: "EGP", value: "EGP" },
//   { label: "ERN", value: "ERN" },
//   { label: "ETB", value: "ETB" },
//   { label: "EUR", value: "EUR" },
//   { label: "FJD", value: "FJD" },
//   { label: "FKP", value: "FKP" },
//   { label: "GBP", value: "GBP" },
//   { label: "GEL", value: "GEL" },
//   { label: "GHS", value: "GHS" },
//   { label: "GIP", value: "GIP" },
//   { label: "GMD", value: "GMD" },
//   { label: "GNF", value: "GNF" },
//   { label: "GTQ", value: "GTQ" },
//   { label: "GYD", value: "GYD" },
//   { label: "HKD", value: "HKD" },
//   { label: "HNL", value: "HNL" },
//   { label: "HRK", value: "HRK" },
//   { label: "HTG", value: "HTG" },
//   { label: "HUF", value: "HUF" },
//   { label: "IDR", value: "IDR" },
//   { label: "ILS", value: "ILS" },
//   { label: "INR", value: "INR" },
//   { label: "IQD", value: "IQD" },
//   { label: "IRR", value: "IRR" },
//   { label: "ISK", value: "ISK" },
//   { label: "JMD", value: "JMD" },
//   { label: "JOD", value: "JOD" },
//   { label: "JPY", value: "JPY" },
//   { label: "KES", value: "KES" },
//   { label: "KGS", value: "KGS" },
//   { label: "KHR", value: "KHR" },
//   { label: "KMF", value: "KMF" },
//   { label: "KPW", value: "KPW" },
//   { label: "KRW", value: "KRW" },
//   { label: "KWD", value: "KWD" },
//   { label: "KYD", value: "KYD" },
//   { label: "KZT", value: "KZT" },
//   { label: "LAK", value: "LAK" },
//   { label: "LBP", value: "LBP" },
//   { label: "LKR", value: "LKR" },
//   { label: "LRD", value: "LRD" },
//   { label: "LSL", value: "LSL" },
//   { label: "LYD", value: "LYD" },
//   { label: "MAD", value: "MAD" },
//   { label: "MDL", value: "MDL" },
//   { label: "MGA", value: "MGA" },
//   { label: "MKD", value: "MKD" },
//   { label: "MMK", value: "MMK" },
//   { label: "MNT", value: "MNT" },
//   { label: "MOP", value: "MOP" },
//   { label: "MRU", value: "MRU" },
//   { label: "MUR", value: "MUR" },
//   { label: "MVR", value: "MVR" },
//   { label: "MWK", value: "MWK" },
//   { label: "MXN", value: "MXN" },
//   { label: "MXV", value: "MXV" },
//   { label: "MYR", value: "MYR" },
//   { label: "MZN", value: "MZN" },
//   { label: "NAD", value: "NAD" },
//   { label: "NGN", value: "NGN" },
//   { label: "NIO", value: "NIO" },
//   { label: "NOK", value: "NOK" },
//   { label: "NPR", value: "NPR" },
//   { label: "NZD", value: "NZD" },
//   { label: "OMR", value: "OMR" },
//   { label: "PAB", value: "PAB" },
//   { label: "PEN", value: "PEN" },
//   { label: "PGK", value: "PGK" },
//   { label: "PHP", value: "PHP" },
//   { label: "PKR", value: "PKR" },
//   { label: "PLN", value: "PLN" },
//   { label: "PYG", value: "PYG" },
//   { label: "QAR", value: "QAR" },
//   { label: "RON", value: "RON" },
//   { label: "RSD", value: "RSD" },
//   { label: "RUB", value: "RUB" },
//   { label: "RWF", value: "RWF" },
//   { label: "SAR", value: "SAR" },
//   { label: "SBD", value: "SBD" },
//   { label: "SCR", value: "SCR" },
//   { label: "SDG", value: "SDG" },
//   { label: "SEK", value: "SEK" },
//   { label: "SGD", value: "SGD" },
//   { label: "SHP", value: "SHP" },
//   { label: "SLL", value: "SLL" },
//   { label: "SOS", value: "SOS" },
//   { label: "SRD", value: "SRD" },
//   { label: "SSP", value: "SSP" },
//   { label: "STN", value: "STN" },
//   { label: "SVC", value: "SVC" },
//   { label: "SYP", value: "SYP" },
//   { label: "SZL", value: "SZL" },
//   { label: "THB", value: "THB" },
//   { label: "TJS", value: "TJS" },
//   { label: "TMT", value: "TMT" },
//   { label: "TND", value: "TND" },
//   { label: "TOP", value: "TOP" },
//   { label: "TRY", value: "TRY" },
//   { label: "TTD", value: "TTD" },
//   { label: "TWD", value: "TWD" },
//   { label: "TZS", value: "TZS" },
//   { label: "UAH", value: "UAH" },
//   { label: "UGX", value: "UGX" },
//   { label: "USD", value: "USD" },
//   { label: "USN", value: "USN" },
//   { label: "UYI", value: "UYI" },
//   { label: "UYU", value: "UYU" },
//   { label: "UZS", value: "UZS" },
//   { label: "VEF", value: "VEF" },
//   { label: "VND", value: "VND" },
//   { label: "VUV", value: "VUV" },
//   { label: "WST", value: "WST" },
//   { label: "XAF", value: "XAF" },
//   { label: "XCD", value: "XCD" },
//   { label: "XDR", value: "XDR" },
//   { label: "XOF", value: "XOF" },
//   { label: "XPF", value: "XPF" },
//   { label: "XSU", value: "XSU" },
//   { label: "XUA", value: "XUA" },
//   { label: "YER", value: "YER" },
//   { label: "ZAR", value: "ZAR" },
//   { label: "ZMW", value: "ZMW" },
//   { label: "ZWL", value: "ZWL" },
// ];
// export const optionsForProductType = [
//   {
//     label: "Virtual",
//     value: "Virtual",
//   },
//   {
//     label: "Physical",
//     value: "Physical",
//   },
//   {
//     label: "Both",
//     value: "Both",
//   },
// ];
// export const optionsForChipProfile = [
//   {
//     label: "Contactless",
//     value: "Contactless",
//   },
//   {
//     label: "Magstripe",
//     value: "Magstripe",
//   },
// ];
// export const optionsForBinType = [
//   {
//     label: "Debit",
//     value: "Debit",
//   },
//   {
//     label: "Prepaid",
//     value: "Prepaid",
//   },
// ];
// export const optionsForServiceCode = [
//   {
//     label: "0101—Magnetic-stipe card; international use",
//     value: "0101—Magnetic-stipe card; international use",
//   },
//   {
//     label: "120—Magnetic-stripe card; international use; PIN is required",
//     value: "120—Magnetic-stripe card; international use; PIN is required",
//   },
//   {
//     label:
//       "121—Magnetic-stripe card; international use; online authorization required for all transactions",
//     value:
//       "121—Magnetic-stripe card; international use; online authorization required for all transactions",
//   },
//   {
//     label: "201—EMV chip card; international use",
//     value: "201—EMV chip card; international use",
//   },
//   {
//     label:
//       "221—EMV chip card; international use; online authorization required for all transactions",
//     value:
//       "221—EMV chip card; international use; online authorization required for all transactions",
//   },
//   {
//     label: "601—National -use EMV chip credit and debit cards",
//     value: "601—National -use EMV chip credit and debit cards",
//   },
// ];
// export const selectDropdownIcon = {
//   name: "down-arrow-icon",
//   id: "down_arrow_icon_2",
//   fillColor: "#011627",
//   width: 8,
//   height: 5,
//   viewBox: "0 0 8.528 4.861",
// };
// export const clientInformationDetail = [
//   {
//     label: "Client Name",
//     placeholder: "Type Name",
//     type: "text",
//     name: "clientName",
//   },
//   {
//     label: "Client Description",
//     ariaLabel: "clientDescription",
//     name: "clientDescription",
//     options: optionForClientDescription,
//     placeholder: "Select",
//     type: "select",
//     suffixIconOptions: selectDropdownIcon,
//   },
//   {
//     label: "Issuer Name",
//     placeholder: "Type Issuer",
//     type: "text",
//     name: "issuerName",
//   },
//   {
//     label: "Contact Person",
//     placeholder: "Type Name",
//     type: "text",
//     name: "contactPerson",
//   },
//   {
//     label: "Contact Person Email",
//     placeholder: "Type Email",
//     type: "text",
//     name: "email",
//   },
//   {
//     label: "Contact Person Mobile",
//     placeholder: "Type Mobile number",
//     type: "text",
//     name: "mobileNo",
//   },
//   {
//     label: "Card Manufacturers",
//     placeholder: "Type Card Manufacturers",
//     type: "text",
//     name: "cardManufactures",
//   },
//   {
//     label: "Address",
//     placeholder: "Type Address",
//     type: "text",
//     name: "address",
//   },
//   {
//     label: "Country",
//     placeholder: "System generated",
//     type: "text",
//     name: "country",
//     disabled: true,
//   },
//   {
//     label: "Company Registration Number",
//     placeholder: "System generated",
//     type: "text",
//     name: "companyRegistrationNumber",
//     disabled: true,
//   },
//   {
//     label: "MC Member ID (if applicable)",
//     options: yesNoOption,
//     type: "radioButtonWithInput",
//     name: "isMCMemberId",
//     inputPlaceholder: "Type MC Member ID",
//     inputName: "mcMemberId",
//   },
//   {
//     label: "Visa Member ID (if applicable)",
//     options: yesNoOption,
//     name: "isVisaMemberId",
//     type: "radioButtonWithInput",
//     inputPlaceholder: "Type Visa Member ID",
//     inputName: "visaMemberId",
//   },
//   {
//     label: "Website",
//     placeholder: "Type Website",
//     type: "text",
//     name: "website",
//   },
//   {
//     label: "Notes",
//     placeholder: "Type Notes",
//     type: "text",
//     name: "notes",
//   },
// ];
// export const productInformationDetail = [
//   {
//     label: "Product ID",
//     placeholder: "System generated",
//     type: "text",
//     name: "productId",
//     disabled: true,
//   },
//   {
//     label: "Product Name",
//     ariaLabel: "productName",
//     name: "productName",
//     type: "text",
//     placeholder: "Type Product Name",
//   },
//   {
//     label: "Product Type",
//     type: "radioButton",
//     name: "productType",
//     options: optionsForProductType,
//   },
//   {
//     label: "Chip Profile",
//     type: "radioButton",
//     name: "chipProfile",
//     options: optionsForChipProfile,
//   },
//   {
//     label: "Network Type",
//     placeholder: "Network Type",
//     type: "text",
//     name: "networkType",
//   },
//   {
//     label: "BIN Type",
//     type: "radioButton",
//     name: "binType",
//     options: optionsForBinType,
//   },
//   {
//     label: "BIN Number",
//     placeholder: "Type BIN Number",
//     type: "text",
//     name: "binNumber",
//   },
//   {
//     label: "BIN Range",
//     placeholder: "Type BIN Range",
//     type: "text",
//     name: "binRange",
//   },
//   {
//     label: "Autoblock Renewal",
//     type: "radioButton",
//     name: "autoblockRenewal",
//     options: yesNoOption,
//   },
//   {
//     label: "Service Code",
//     placeholder: "Select Service Code",
//     type: "select",
//     name: "serviceCode",
//     suffixIconOptions: selectDropdownIcon,
//     options: optionsForServiceCode,
//   },
//   {
//     label: "Expiry Period (Months)",
//     placeholder: "Type Expiry Period",
//     type: "text",
//     name: "expiryPeriod",
//   },
//   {
//     label: "Country of Issue",
//     placeholder: "Select Country of Issue",
//     type: "select",
//     name: "country",
//     suffixIconOptions: selectDropdownIcon,
//     options: countryOfIssueList,
//   },
//   {
//     label: "Billing Currency",
//     placeholder: "Select Billing Currency",
//     type: "select",
//     name: "billingCurrency",
//     suffixIconOptions: selectDropdownIcon,
//     options: currency,
//   },
//   {
//     label: "Settlement Currency",
//     placeholder: "Select Settlement Currency",
//     type: "select",
//     name: "settlementCurrency",
//     suffixIconOptions: selectDropdownIcon,
//     options: currency,
//   },
//   {
//     label: "Base Currency",
//     placeholder: "Select Base Currency",
//     type: "select",
//     name: "baseCurrency",
//     suffixIconOptions: selectDropdownIcon,
//     options: currency,
//   },
//   {
//     label: "Chargeback Management System",
//     placeholder: "Type Chargeback Management System",
//     type: "text",
//     name: "chargebackManagementSystem",
//   },
// ];
// export const rulesDetail = [
//   {
//     label: "ATM withdrawals - maximum value per transaction",

//     placeholder: "What is the maximum single ATM withdrawal amount?",
//     type: "text",
//     name: "atmMinimumValuePerTransaction",
//   },
//   {
//     label: "ATM withdrawals - maximum transactions per day",

//     placeholder: "How many ATM transactions are allowed per day?",
//     type: "text",
//     name: "atmMaximumTransactionsPerDay",
//   },
//   {
//     label: "ATM withdrawal - Transaction exceeds daily (value) limit",

//     placeholder: "What is the daily maximum ATM withdrawal amount?",
//     type: "text",
//     name: "atmTransactionLimit",
//   },
//   {
//     label: "ATM transactions within 1 hour",
//     placeholder:
//       "How many ATM transactions are allowed in 1 hour before a decline?",
//     type: "text",
//     name: "atmTransactions",
//   },
//   {
//     label: "ATM balance checks within 1 hour",
//     placeholder:
//       "How many ATM balance checks are allowed in 1 hour before a decline?",
//     type: "text",
//     name: "atmBalanceChecks",
//     width: "95%",
//   },
//   {
//     label: "Frequent ATM balance enquiry, withdrawal and pin change",
//     placeholder:
//       "How long before a transaction can be authorised between last balance, PIN and withdrawal?",
//     type: "text",
//     name: "atmBalanceEnquiry",
//     width: "95%",
//   },
//   {
//     label: "Purchases - maximum value per transaction",

//     placeholder: "What is the maximum single POS purchase amount?",
//     type: "text",

//     name: "purchaseMaximumValuePerTransaction",
//   },
//   {
//     label: "POS Purchases - maximum number per day",

//     placeholder: "How many POS purchases are allowed per day?",
//     type: "text",

//     name: "purchaseMaximumNumberPerDay",
//   },
//   {
//     label: "E-commerce - maximum value per transaction",

//     placeholder: "What is the maximum single eCommerce purchase amount?",
//     type: "text",

//     name: "eCommerceMaximumValuePerTransaction",
//   },
//   {
//     label: "E-commerce - maximum number per day",

//     placeholder: "How many eCommerce purchase s are allowed per day?",
//     type: "text",

//     name: "eCommerceMaximumNumberPerDay",
//   },
//   {
//     label: "Multi-country 24-hour rule",
//     placeholder:
//       "How many different countries can the card be used in, within a 24 hour period?",
//     type: "text",

//     name: "multiCountryRule",
//     width: "95%",
//   },
//   {
//     label: "Multi-country within 50 minutes",

//     placeholder:
//       "How many transactions are allowed in multiple countries within 50 minutes?",
//     type: "text",

//     name: "multiCountryIn50Min",
//     width: "95%",
//   },
//   {
//     label: "High-frequency retailer use",

//     placeholder:
//       "How many transactions are allowed at the same merchant in 6 hours?",
//     type: "text",

//     width: "95%",
//     name: "highFrequencyRetailerUse",
//   },
//   {
//     label: "PSD2 rules 1",
//     placeholder:
//       "How many contactless transactions before re-authentication is required?",
//     type: "text",

//     name: "psd2Rule1",
//     width: "95%",
//   },
//   {
//     label: "PSD2 rules 2",
//     placeholder:
//       "What is the cumulative contactless transactions amount before re-authentication?",
//     type: "text",

//     name: "psd2Rule2",
//     width: "95%",
//   },
//   {
//     label: "PSD2 rules 3",
//     placeholder: "How many attempted transactions before re-authentication?",
//     type: "text",

//     name: "psd2Rule3",
//   },
//   {
//     label: "High-risk country rule",

//     placeholder: "Which countries should the card NOT work in?",
//     type: "text",

//     name: "highRiskCountryRule",
//   },
// ];
// export const tokenFlagsDetail = [
//   {
//     label: "Generate new random Pin and send to Card on next EMV Transaction",
//     type: "radioButton",
//     name: "randomPin",
//     options: yesNoOption,
//   },
//   {
//     label: "Send Card Balance in De54 in Next Authorisation",
//     type: "radioButton",
//     name: "cardBalanceInDe54",
//     options: yesNoOption,
//   },
//   {
//     label: "Send Pin unblock to Card",
//     type: "radioButton",
//     name: "pinUnblockToCard",
//     options: yesNoOption,
//   },
//   {
//     label: "Set Card to Decline all Transaction Except E-commerce",
//     type: "radioButton",
//     name: "cardToDeclineAllTransactionExceptEcommerce",
//     options: yesNoOption,
//   },
//   {
//     label: "Set Card to Decline all Contactless Transactions",
//     type: "radioButton",
//     name: "cardToDeclineAllTransaction",
//     options: yesNoOption,
//   },
//   {
//     label: "Sets Card to Decline all E-commerce Transactions",
//     type: "radioButton",
//     name: "cardToDeclineAllEcommerceTransaction",
//     options: yesNoOption,
//   },
//   {
//     label: "Set Card to Decline all ATM Cash Withdrawals",
//     type: "radioButton",
//     name: "cardToDeclineAllAtmCashWithdrawals",
//     options: yesNoOption,
//   },
//   {
//     label: "Set Card to Decline Balance Enquiry over Auth",
//     type: "radioButton",
//     name: "cardToDeclineBalanceEnquiry",
//     options: yesNoOption,
//   },
//   {
//     label: "Set Card to Decline Cash back Transactions",
//     type: "radioButton",
//     name: "cardToDeclineCashbackTransaction",
//     options: yesNoOption,
//   },
//   {
//     label: "Set Card to Decline Credit Purchase/refund (DE3_1=20)",
//     type: "radioButton",
//     name: "cardToDeclineCreditPurchase20",
//     options: yesNoOption,
//   },
//   {
//     label: "Set Card to Decline Credit Payment (DE3_1=28)",
//     type: "radioButton",
//     name: "cardToDeclineCreditPurchase28",
//     options: yesNoOption,
//   },
//   {
//     label: "Set Card to Decline non Base-currency Transactions",
//     type: "radioButton",
//     name: "cardToDeclineNonBaseCurrencyTransactions",
//     options: yesNoOption,
//   },
//   {
//     label: "Set Card to Decline all Account Enquiries (de61_7=8)",
//     type: "radioButton",
//     name: "cardToDeclineAllAccountInquiry",
//     options: yesNoOption,
//   },
//   {
//     label: "Set Card to Decline Card not Present Transactions",
//     type: "radioButton",
//     name: "cardToDeclineCardNotPresentTransactions",
//     options: yesNoOption,
//   },
//   {
//     label:
//       "Set Card to Decline Cardholder not Present Transactions (Excluding Recurring and E-commerce)",
//     type: "radioButton",
//     name: "cardToDeclineCardHolderNotPresetTransaction",
//     options: yesNoOption,
//     width: "95%",
//   },
//   {
//     label: "Set Card to Decline fail-over to mag at EMV-capable ATM",
//     type: "radioButton",
//     name: "cardToDeclineFailOverToMagAtEMV",
//     options: yesNoOption,
//   },
//   {
//     label: "Set Card to Decline mag at ATM",
//     type: "radioButton",
//     name: "cardToDeclineFailOverToMagAtATM",
//     options: yesNoOption,
//   },
//   {
//     label: "Allow if Fallback chp -> mag at chp Capable pos DE22_1=80",
//     type: "radioButton",
//     name: "fallbackChip",
//     options: yesNoOption,
//   },
//   {
//     label:
//       "Allow if de22_1=02 & De48_88 =Y de22_1=02 = PAN auto-entry via magnetic stripe—track data is not required. DE48 =Y = Authorization Platform replaced DE 22, subfield 1, value 90 or 91 with value 02. I.e. its a magstripe fallback for cases where the chip fails validation.",
//     type: "radioButton",
//     name: "allowIfPanAutoEntry",
//     options: yesNoOption,
//     width: "95%",
//   },
//   {
//     label: "Set Card to Approve if AVS Match Fails",
//     type: "radioButton",
//     name: "cardToApproveIfAvsMatchFails",
//     options: yesNoOption,
//   },
//   {
//     label: "Set Card to Decline Recurring Transaction",
//     type: "radioButton",
//     name: "cardToDeclineRecurringTransaction",
//     options: yesNoOption,
//   },
//   {
//     label: "Set Card to External Auth OFF",
//     type: "radioButton",
//     name: "cardToExternalAuthOff",
//     options: yesNoOption,
//   },
// ];
// export const validationRegex = {
//   mobileNo: PHONE_NUMBER_WITH_COUNTRY_CODE_VALIDATION_REGEX,
//   email: EMAIL_VALIDATION_REGEX,
//   binNumber: NUMBER_VALIDATION_REGEX,
//   binRange: NUMBER_VALIDATION_REGEX,
//   expiryPeriod: NUMBER_VALIDATION_REGEX,
//   atmMinimumValuePerTransaction: NUMBER_VALIDATION_REGEX,
//   atmMaximumTransactionsPerDay: NUMBER_VALIDATION_REGEX,
//   atmTransactionLimit: NUMBER_VALIDATION_REGEX,
//   atmTransactions: NUMBER_VALIDATION_REGEX,
//   atmBalanceChecks: NUMBER_VALIDATION_REGEX,
//   atmBalanceEnquiry: NUMBER_VALIDATION_REGEX,
//   purchaseMaximumValuePerTransaction: NUMBER_VALIDATION_REGEX,
//   purchaseMaximumNumberPerDay: NUMBER_VALIDATION_REGEX,
//   eCommerceMaximumValuePerTransaction: NUMBER_VALIDATION_REGEX,
//   eCommerceMaximumNumberPerDay: NUMBER_VALIDATION_REGEX,
//   multiCountryRule: NUMBER_VALIDATION_REGEX,
//   multiCountryIn50Min: NUMBER_VALIDATION_REGEX,
//   highFrequencyRetailerUse: NUMBER_VALIDATION_REGEX,
//   psd2Rule1: NUMBER_VALIDATION_REGEX,
//   psd2Rule2: NUMBER_VALIDATION_REGEX,
//   psd2Rule3: NUMBER_VALIDATION_REGEX,
// };
