import axios from "axios";

const apiService = axios.create(
  localStorage && localStorage.user && localStorage.user.token
    ? {
        headers: { Authorization: "Bearer " + localStorage.user.token },
      }
    : {}
);

export default apiService;
