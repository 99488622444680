import React, { useEffect, useState } from "react";
import LoginComponent from "../../components/Login/Login";

import useFetch from "../../hooks/useAPI";
import history from "../../history";
import { getValue } from "../../utils/commonUtils";
const Login = (props) => {
  const [login, doLogin] = useFetch("LOGIN_USER", { lazy: true });
  const [isResetMessage, setResetMessage] = useState(false);
  const [isSetMessage, setSetMessage] = useState(false);

  useEffect(() => {
    if (login && login.data) {
      if (login.data.errorStatus === 403) {
      } else {
        localStorage.setItem("user", JSON.stringify(login.data));
        localStorage.setItem("userSession", new Date());
        console.log("login.data", login.data);
        if (
          login &&
          login.data &&
          login.data.roles &&
          login.data.roles.includes("administrator")
        ) {
          history.push("/");
        } else {
          history.push("/orders");
        }
      }
    }
  }, [login]);

  useEffect(() => {

    console.log(props)
    const search = getValue(props, "props.location.search"); // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const isMessage = params.get("message");
    
    if (isMessage === "reset") {
      setResetMessage(true);
      setTimeout(function () {
        setResetMessage(false);
      }, 30000)
    } else if (isMessage === "set") {
      setSetMessage(true);
      setTimeout(function () {
        setSetMessage(false);
      }, 30000)
    }

  }, [getValue(props, "props.location.search")]);
  const onSubmit = (payload) => {
    doLogin({ payload });
  };

  return <LoginComponent onSubmit={onSubmit} login={login} isResetMessage={isResetMessage} isSetMessage={isSetMessage} />;
};
export default Login;
