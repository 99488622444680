import React, { useState, useEffect } from "react";
import history from "../../history";
import SetPasswordView from "./SetPassword.view";
const SetPasswordComponent = ({ onSubmit, keyData, message, emailData }) => {
  const [formData, setFormData] = useState({
    key: "",
    email: "",
    password: "",
    confirmpassword: ""
  });
  const [passwordError, setPasswordError] = useState("");
  const [errorData, setErrorData] = useState({
    email: "",
    password: "",
    confirmpassword: ""
  });

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setFormData({
      ...formData,
      ["key"]: keyData,
      ["email"]: emailData,
    });
  }, [keyData, emailData]);

  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onClickSetPassword = () => {
    let error = { ...errorData };
    if (formData.email === "" || formData.password === "" || formData.confirmpassword === '' || formData.confirmpassword != formData.password) {
      if (formData.email === "") {
        error.email = "Per favore, inserisci il tuo indirizzo email";
      }
      if (formData.password === "") {
        error.password = "Per favore, inserisci la password";
      }
      if (formData.confirmpassword === "") {
        error.confirmpassword = "Please enter confirm password";
      }
      if (formData.confirmpassword != "" && formData.password != "" && formData.confirmpassword != formData.password) {
        error.confirmpassword = "Le password inserite non corrispondono";
      }
      setErrorData(error);
      return false;
    } else {
      setLoader(true);
      setErrorData({ email: "", password: "", confirmpassword: "" });
      onSubmit(formData);
      setFormData({
        ...formData,
        ["password"]: "",
        ["confirmpassword"]: "",
        ["email"]: "",
      });
    }
    // if (formData.password === "") {
    //   setPasswordError("Please enter password!");
    // } else {
    //   setLoader(true);
    //   setPasswordError("");
    //   onSubmit(formData);
    //   setFormData({
    //     ...formData,
    //     ["password"]: "",
    //   });
    // }
  };

  const redirectToHomePage = () => {
    history.push("/");
  };

  return (
    <SetPasswordView
      onClickSetPassword={onClickSetPassword}
      formData={formData}
      handleOnChange={handleOnChange}
      message={message}
      redirectToHomePage={redirectToHomePage}
      loader={loader}
      passwordError={passwordError}
      errorData={errorData}
    />
  );
};
export default SetPasswordComponent;
