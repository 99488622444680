import React, { useEffect, useState } from "react";
import SetPasswordComponent from "../../components/ResetPassword/SetPassword";
import useFetch from "../../hooks/useAPI";
import { getValue } from "../../utils/commonUtils";
import history from "../../history";
const SetPassword = (props) => {
  const [keyData, setKeyData] = useState("");
  const [emailData, setEmailData] = useState("");
  const [message, setMessage] = useState("");


  const [userPasswordData, setUserPassowrd] = useFetch("SET_USER_PASSWORD", {
    lazy: true,
  });

  useEffect(() => {
    const search = getValue(props, "props.location.search"); // could be '?foo=bar'
    const params = new URLSearchParams(search);
    setKeyData(params.get("key"));
    setEmailData(params.get("email"));
  }, [getValue(props, "props.location.search")]);

  useEffect(() => {
    console.log("userPasswordData", userPasswordData);
    if (userPasswordData && userPasswordData.data !== undefined) {
      setMessage("Your password set successfully!");
      history.push("/login/?message=set");
    }
    return () => {
      setMessage("");
    };
  }, [userPasswordData]);
  console.log("userPasswordData", userPasswordData);
  const onSubmit = (payload) => {
    setUserPassowrd({ payload });
  };
  return (
    <SetPasswordComponent
      onSubmit={onSubmit}
      keyData={keyData}
      emailData={emailData}
      message={message}
    />
  );
};
export default SetPassword;
