import React, { useState } from "react";
import history from "../../history";
import ResetPasswordView from "./ResetPassword.view";
const ResetPasswordComponent = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [loader, setLoader] = useState(false);

  const [emailError, setEmailError] = useState("");

  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onClickResetPassword = () => {
    if (formData.email === "") {
      setEmailError("Please enter email!");
    } else {
      setLoader(true);
      setEmailError("");
      onSubmit(formData);
    }
  };

  const redirectToHomePage = () => {
    history.push("/");
  };

  return (
    <ResetPasswordView
      onClickResetPassword={onClickResetPassword}
      handleOnChange={handleOnChange}
      formData={formData}
      redirectToHomePage={redirectToHomePage}
      loader={loader}
      emailError={emailError}
    />
  );
};
export default ResetPasswordComponent;
