import React from "react";
import { getValue } from "../../utils/commonUtils";
import Chart from "react-apexcharts";
import LoaderComponent from "./../Loader/Loader";

const DashboardView = ({ dashboardData, loader }) => {
  const retunDataForBestProduct = (dataType, type) => {
    let sdata = [];
    dashboardData[dataType].map((data) => {
      let series = data[type];
      if (type === "total_sale") {
        series = parseFloat(series);
      }
      sdata.push(series);
    });
    return sdata;
  };

  return (
    <div className="AppMainContent">
      <div class="react-bosch-loader">{loader && <LoaderComponent />}</div>
      <div class="main-content">
        <div class="page-content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-md-4">
                    <div class="card">
                      <div class="card-body">
                        <div class="media">
                          <div class="media-body overflow-hidden">
                            <p class="text-truncate font-size-14 mb-2">
                              Ordini accettati
                            </p>
                            <h4 class="mb-0">
                              {getValue(
                                dashboardData,
                                "dashboardData.order_status.wc-processing"
                              ) || "Nessun dato da mostrare"}
                            </h4>
                          </div>
                          <div class="text-primary">
                            <i class="ri-stack-line font-size-24"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card">
                      <div class="card-body">
                        <div class="media">
                          <div class="media-body overflow-hidden">
                            <p class="text-truncate font-size-14 mb-2">
                              Ordini in attesa
                            </p>
                            <h4 class="mb-0">
                              {getValue(
                                dashboardData,
                                "dashboardData.order_status.wc-on-hold"
                              ) || "Nessun dato da mostrare"}
                            </h4>
                          </div>
                          <div class="text-primary">
                            <i class="ri-store-2-line font-size-24"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card">
                      <div class="card-body">
                        <div class="media">
                          <div class="media-body overflow-hidden">
                            <p class="text-truncate font-size-14 mb-2">
                              Ordini cancellati
                            </p>
                            <h4 class="mb-0">
                              {" "}
                              {getValue(
                                dashboardData,
                                "dashboardData.order_status.wc-cancelled"
                              ) || "Nessun dato da mostrare"}
                            </h4>
                          </div>
                          <div class="text-primary">
                            <i class="ri-briefcase-4-line font-size-24"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-4">
                    <div class="card">
                      <div class="card-body">
                        <div class="float-right d-none d-md-inline-block">
                          {/* <div class="btn-group mb-2">
                            <button
                              type="button"
                              class="btn btn-sm btn-light active"
                            >
                              Tutta la campagna
                            </button>
                            <button type="button" class="btn btn-sm btn-light">
                              Oggi
                            </button>
                          </div> */}
                        </div>
                        <h4 class="card-title mb-4">Provenienza Lead</h4>
                        <div>
                          {dashboardData && dashboardData !== null && (
                            <Chart
                              options={{
                                labels: Object.keys(
                                  dashboardData.order_by_domain
                                ),
                                noData: {
                                  text: "Non ci sono dati da mostrare",
                                  align: "center",
                                  verticalAlign: "middle",
                                  offsetX: 0,
                                  offsetY: 0,
                                  style: {
                                    // color: undefined,
                                    fontSize: "14px",
                                    fontFamily: "Bosch Sans",
                                  },
                                },
                                fill: {
                                  colors: ["#134e6f", "#f2a154"],
                                },
                                legend: {
                                  position: "bottom",
                                  markers: {
                                    fillColors: ["#134e6f", "#f2a154"],
                                  },
                                },
                              }}
                              series={
                                dashboardData.order_by_domain
                                  ? Object.values(dashboardData.order_by_domain)
                                  : []
                              }
                              type="pie"
                              width="100%"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="card">
                      <div class="card-body">
                        <h4 class="card-title mb-4">I 5 migliori dealer</h4>

                        <div id="bar_chart" class="apex-charts" dir="ltr">
                          {dashboardData && (
                            <Chart
                              options={{
                                labels: retunDataForBestProduct(
                                  "top_5_dealer",
                                  "company_name"
                                ),
                                noData: {
                                  text: "Non ci sono dati da mostrare",
                                  align: "center",
                                  verticalAlign: "middle",
                                  offsetX: 0,
                                  offsetY: 0,
                                  style: {
                                    // color: undefined,
                                    fontSize: "14px",
                                    fontFamily: "Bosch Sans",
                                  },
                                },
                                fill: {
                                  colors: [
                                    "#d54062",
                                    "#ffa36c",
                                    "#ebdc87",
                                    "#799351",
                                  ],
                                },
                                legend: {
                                  position: "bottom",
                                  markers: {
                                    fillColors: [
                                      "#d54062",
                                      "#ffa36c",
                                      "#ebdc87",
                                      "#799351",
                                    ],
                                  },
                                },
                              }}
                              series={
                                retunDataForBestProduct(
                                  "top_5_dealer",
                                  "total_sale"
                                ) || []
                              }
                              type="donut"
                              width="100%"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-4">
                    <div class="card">
                      <div class="card-body">
                        <h4 class="card-title mb-4">I 5 migliori prodotti</h4>
                        {dashboardData && (
                          <Chart
                            options={{
                              labels: retunDataForBestProduct(
                                "product_stats",
                                "product_name"
                              ),
                              noData: {
                                text: "Non ci sono dati da mostrare",
                                align: "center",
                                verticalAlign: "middle",
                                offsetX: 0,
                                offsetY: 0,
                                style: {
                                  // color: undefined,
                                  fontSize: "14px",
                                  fontFamily: "Bosch Sans",
                                },
                              },
                              fill: {
                                colors: [
                                  "#16c79a",
                                  "#134e6f",
                                  "#134e6f",
                                  "#f37121",
                                  "#d92027",
                                ],
                              },
                              legend: {
                                position: "bottom",
                                markers: {
                                  fillColors: [
                                    "#16c79a",
                                    "#134e6f",
                                    "#134e6f",
                                    "#f37121",
                                    "#d92027",
                                  ],
                                },
                              },
                            }}
                            series={
                              retunDataForBestProduct(
                                "product_stats",
                                "total_sale"
                              ) || []
                            }
                            type="pie"
                            width="100%"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DashboardView;
