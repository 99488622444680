import React, { useState, useEffect } from "react";
import LoginView from "./Login.view";
import history from "../../history";

const LoginComponent = ({ onSubmit, login, isResetMessage, isSetMessage }) => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [errorData, setErrorData] = useState({
    username: "",
    password: "",
  });



  useEffect(() => {
    if (login && login.data && login.data.errorStatus === 403) {
      setError("Nome utente o password non corretti.");
      setLoader(false);
    }
  }, [login]);

  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onLogin = () => {
    let error = { ...errorData };
    if (formData.username === "" || formData.password === "") {
      if (formData.username === "") {
        error.username = "Please enter username";
      }
      if (formData.password === "") {
        error.password = "Please enter password";
      }
      setErrorData(error);
      return false;
    } else {
      setLoader(true);
      setErrorData({ username: "", password: "" });
      setError("");
      onSubmit(formData);
    }
  };

  const redirectToHomePage = (path) => {
    history.push(path);
  };

  return (
    <LoginView
      redirectToHomePage={redirectToHomePage}
      formData={formData}
      handleOnChange={handleOnChange}
      onLogin={onLogin}
      loader={loader}
      errorData={errorData}
      error={error}
      isResetMessage={isResetMessage}
      isSetMessage={isSetMessage}
    />
  );
};
export default LoginComponent;
