import React from "react";
import { useSelector } from "react-redux";
import DashboardView from "./Dashboard.view";
import HeaderComponent from "../Header/Header";
import FooterComponent from "../Footer/Footer";
import SidebarComponent from "../Sidebar/Sidebar";
import "./styles.css";

const DashboardComponent = ({ dashboardData, loader }) => {
  return (
    <>
      <HeaderComponent />
      <DashboardView dashboardData={dashboardData} loader={loader} />
      <SidebarComponent />
      <FooterComponent />
    </>
  );
};
export default DashboardComponent;
