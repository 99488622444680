import React from "react";
import LoaderComponent from "./../Loader/Loader";
const LoginView = ({
  formData,
  handleOnChange,
  onLogin,
  redirectToHomePage,
  loader,
  errorData,
  error,
  isResetMessage,
  isSetMessage
}) => {
  return (
    <div>
      {loader && <LoaderComponent />}
      <div className="auth-body-bg">
        <div>
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              <div className="col-lg-4">
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div>
                          <div className="text-center">
                            <div>
                              {/* <a onClick={()=> redirectToHomePage("/")} className="logo"><img src="./assets/images/Bosch-Logo.png" height="100" alt="logo" /></a> */}
                              <a
                                onClick={() => redirectToHomePage("/")}
                                className="logo"
                              >
                                <img
                                  src="/images/bosch-power-days.svg"
                                  height="100"
                                  alt="logo"
                                />
                              </a>
                            </div>

                            <h4 className="font-size-18 mt-4">
                              Area riservata
                            </h4>
                            <p className="text-muted">
                              Accedi all'area riservata di Bosch Power Days.
                            </p>
                          </div>
                          {isResetMessage && <div className="notify-wrap">
                            <p>Controlla la tua casella di posta (Posta in arrivo, SPAM o Promozioni) e segui la procedura per generare la tua password. </p>
                          </div>}
                          {isSetMessage && <div className="notify-wrap">
                            <p>Password generata con successo. <br></br>Puoi effettuare il login con le nuove credenziali.</p>
                          </div>}
                          <div className="p-2 mt-3">
                            {/* <form
                              className="form-horizontal"
                              action="/"
                            > */}
                            <div className="form-group auth-form-group-custom mb-4">
                              <i className="ri-user-2-line auti-custom-input-icon"></i>
                              <label for="email">Username</label>
                              <input
                                type="text"
                                className="form-control"
                                id="email"
                                name="username"
                                value={formData.username}
                                onChange={(e) => handleOnChange(e)}
                                placeholder="Il tuo username"
                              />
                              <span className="error">
                                {errorData.username}
                              </span>
                            </div>

                            <div className="form-group auth-form-group-custom mb-4">
                              <i className="ri-lock-2-line auti-custom-input-icon"></i>
                              <label for="userpassword">Password</label>
                              <input
                                type="password"
                                value={formData.password}
                                name="password"
                                onChange={(e) => handleOnChange(e)}
                                className="form-control"
                                id="userpassword"
                                placeholder="La tua password"
                              />
                              <span className="error">
                                {errorData.password}
                              </span>
                            </div>

                            <span className="error">{error}</span>

                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customControlInline"
                              />
                              <label
                                className="custom-control-label"
                                for="customControlInline"
                              >
                                Ricordami
                              </label>
                            </div>

                            <div className="mt-4 text-center">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                // type="submit"
                                onClick={() => onLogin()}
                              >
                                Accedi
                              </button>
                            </div>

                            <div className="mt-4 text-center">
                              <a
                                onClick={() =>
                                  redirectToHomePage("/reset-password")
                                }
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock mr-1"></i>Hai
                                dimenticato la password?
                              </a>
                            </div>
                            {/* </form> */}
                          </div>

                          <div className="mt-5 text-center">
                            <p>
                              © 2024{" "}
                              <strong>Robert Bosch Power Tools GmbH<br/>P.IVA 00720460153</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginView;
