import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import EditOrderView from "./EditOrder.view";
import HeaderComponent from "../Header/Header";
import FooterComponent from "../Footer/Footer";
import Swal from "sweetalert2";
import { getValue } from "../../utils/commonUtils";

const EditOrderComponent = ({
  order,
  onApiCall,
  updateOrderData,
  isUpdateSuccess,
  onCloseConfirmationPopUp,
}) => {
  const loginData = useSelector((state) => state.applicationReducer.login);

  const returnLoginData = (data) => {
    if (data === undefined) {
      return JSON.parse(localStorage.getItem("user"));
    } else {
      return data.data;
    }
  };

  const [orderData, setOrderData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const [note, setNote] = useState("");
  const onClose = () => {
    Swal.fire({
      icon: "question",
      title: "Vuoi accettare questa richiesta?",
      input: "textarea",
      inputValue: note,
      inputLabel: "Note per il cliente",
      inputPlaceholder:
        "Queste informazioni saranno inviate all'utente che ha fatto la richiesta.",
      inputAttributes: {
        "aria-label": "Ine",
      },
      showDenyButton: true,
      confirmButtonText: "Si, voglio accettare!",
      denyButtonText: "No, voglio rifiutare!",
      showLoaderOnConfirm: true,
      confirmButtonColor: "#1cbb8c",
      denyButtonColor: "#ff3d60",
      returnInputValueOnDeny : true
    }).then((result) => {
      console.log("result", result)
      if (result.isConfirmed) {
        console.log("result", result);
        onClickAccept(result.value);
        Swal.fire({
          icon: "success",
          title: "Grazie!",
          html: "L'ordine è stato aggiornato con successo.",
        });
      } else if (result.isDenied) {
        onClickDecline(result.value);
        Swal.fire({
          icon: "success",
          title: "Grazie!",
          html: "L'ordine è stato aggiornato con successo.",
        });
      }
    });
  };

  useEffect(() => {
    if (order && order.data) {
      setLoader(false);
      setOrderData(order.data);
    }
  }, [order]);

  useEffect(() => {
    if (
      updateOrderData &&
      updateOrderData.data &&
      JSON.stringify(order.data) !== JSON.stringify(updateOrderData.data)
    ) {
      setOrderData(updateOrderData.data);
    }
  }, [updateOrderData && updateOrderData.data]);

  const onClickAccept = (notedata) => {
    onApiCall({ id: order.data.id, status: "wc-processing", note: notedata });
    setNote(note);
    onClose();
  };

  const onClickDecline = (notedata) => {
    onApiCall({ id: order.data.id, status: "wc-cancelled", note: notedata });
    setNote(note);
    onClose();
  };

  return (
    <>
      <HeaderComponent />
      <EditOrderView
        order={orderData}
        onClose={onClose}
        isOpen={isOpen}
        setNote={setNote}
        note={note}
        onClickAccept={onClickAccept}
        onClickDecline={onClickDecline}
        onCloseConfirmationPopUp={onCloseConfirmationPopUp}
        isUpdateSuccess={isUpdateSuccess}
        loader={order && order.loading}
        loginData={returnLoginData(loginData)}
      />
      <FooterComponent />
    </>
  );
};
export default EditOrderComponent;
