import Loader from "react-loader-spinner";
import "./Loader.scss";
const LoaderView = () => {
  return (
    <>
      <div class="loader-search-wrap">
        <div class="loader-search simple-circle"></div>
      </div>
    </>
  );
};
export default LoaderView;
