import React from "react";
const FooterView = () => {
  return (
    <div>
      <footer class="footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-4">
              <strong>{new Date().getFullYear()}</strong> ©{" "}
              <strong>Robert Bosch Power Tools GmbH<br/>P.IVA 00720460153</strong>
            </div>
            <div class="col-sm-8">
              <div class="text-sm-right d-none d-sm-block">
                <ul class="d-flex justify-content-end fa-ul">
                  <li class="mr-3">
                    <a target="_blank" href="https://boschpowerdays.it/servizio-clienti/regolamento-iniziativa/">Regolamento Iniziativa</a>
                  </li>
                  <li class="mr-3">
                    <a target="_blank" href="https://boschpowerdays.it/servizio-clienti/informativa-sulla-privacy/">Informativa sulla privacy</a>
                  </li>
                  <li class="mr-3">
                    <a target="_blank" href="https://boschpowerdays.it/servizio-clienti/condizioni-uso/">Condizioni d'uso</a>
                  </li>
                  <li class="mr-0">
                    <a target="_blank" href="https://boschpowerdays.it/servizio-clienti/informazioni-societarie/">Informazioni societarie</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default FooterView;
