import React from "react";
import { getValue } from "../../utils/commonUtils";
import ModalComponent from "../CustomeComponent/ModalComponent";
import { formatter } from "../../utils/commonUtils";
import ActiveImg from "../../assets/images/done.svg";
import DomandaImg from "../../assets/images/domanda.svg";
import LoaderComponent from "./../Loader/Loader";
import Swal from "sweetalert2";

const EditOrderView = ({
  order,
  onClose,
  isOpen,
  setNote,
  note,
  onClickAccept,
  onClickDecline,
  onCloseConfirmationPopUp,
  isUpdateSuccess,
  loader,
  loginData
}) => {
  const modalCom = async () => { };

  return (
    <>
      <div>
        <div class="react-bosch-loader">{loader && <LoaderComponent />}</div>
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row mb-3 align-items-center">
                <div className="col-6">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0">
                      Ordine #{getValue(order, "order.id")}
                    </h4>
                  </div>
                </div>
                <div class="col-6">
                  <div class="d-flex justify-content-end align-items-center">
                    <div
                      className={
                        getValue(order, "order.status.id") === "wc-processing"
                          ? "badge badge-soft-success font-size-20"
                          : getValue(order, "order.status.id") ===
                            "wc-cancelled"
                            ? "badge badge-soft-danger font-size-20"
                            : "badge badge-soft-warning font-size-20"
                      }
                      // class="badge badge-soft-success font-size-20"
                      style={{
                        backgroundcolor: `${getValue(
                          order,
                          "order.status.color"
                        )}`,
                      }}
                    >
                      {getValue(order, "order.status.label")}
                    </div>
                    {
                      loginData &&
                      loginData.roles &&
                      !loginData.roles.includes("administrator") &&
                      getValue(order, "order.status.id") !== "wc-processing" &&
                      getValue(order, "order.status.id") !== "wc-cancelled" && (
                        <div class="ml-3 alert alert-danger mb-0" role="alert">
                          <a
                            onClick={() => onClose()}
                            id="update-status-order"
                            class="alert-link"
                          >
                            Devi aggionare lo stato dell'ordine:{" "}
                            <span>Clicca qui</span>
                          </a>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table
                          id="datatable-buttons"
                          className="products-order-table table dt-responsive nowrap w-100"
                        >
                          <thead className="thead-light">
                            <tr>
                              <th>Immagine</th>
                              <th>Prodotto</th>
                              <th>Prezzo</th>
                              <th>Quantità</th>
                              <th>Totale</th>
                            </tr>
                          </thead>

                          <tbody>
                            {getValue(order, "order.items.length") > 0 &&
                              getValue(order, "order.items").map(
                                (orderData) => {
                                  return (
                                    <tr>
                                      <td>
                                        <img
                                          src={orderData.image}
                                          alt={orderData.name}
                                          title="product-img"
                                          className="avatar-md"
                                        />
                                      </td>
                                      <td>
                                        <h5 className="font-size-16">
                                          <a className="text-dark">
                                            {orderData.name}
                                          </a>
                                        </h5>
                                        <p className="mb-0 font-size-12">
                                          <span className="font-weight-medium">
                                            Cod.Referenza: {orderData.sku}
                                          </span>
                                        </p>
                                      </td>
                                      <td>
                                        {
                                          orderData.total > 0 ?
                                            formatter(orderData.total, 2) + " IVA" : "Gratuito"
                                        }
                                      </td>
                                      <td>{orderData.quantity}</td>
                                      <td>
                                        {
                                          orderData.total > 0 ?
                                            formatter(orderData.total, 2) + " IVA" : "Gratuito"
                                        }
                                      </td>
                                    </tr>
                                  );
                                }
                              )}

                            <tr className="table-footer bg-light color-primary font-size-22 font-weight-bold text-right">
                              <th
                                className="text-right text-uppercase font-weight-bold"
                                scope="row"
                                colspan="3"
                              >
                                Totale :
                              </th>

                              <td colspan="2">
                                <span class="text-right">
                                  {formatter(getValue(order, "order.total"), 2)}{" "}
                                  + IVA
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Cliente</h4>
                      <p className="card-title-desc">
                        Dati anagrafici del cliente.
                      </p>
                      <form action="">
                        <div className="row">
                          <div className="col-12 mb-3">
                            <label>Società</label>
                            <input
                              type="text"
                              className="form-control"
                              name="client-company"
                              id="client-company"
                              value={getValue(order, "order.company")}
                              disabled
                            />
                          </div>
                          <div className="col-12 mb-3">
                            <label>Partita IVA</label>
                            <input
                              type="text"
                              className="form-control"
                              name="client-vat"
                              id="client-vat"
                              value={getValue(order, "order.vat")}
                              disabled
                            />
                          </div>
                          <div className="col-6 mb-3">
                            <label>Nome</label>
                            <input
                              type="text"
                              className="form-control"
                              name="client-name"
                              id="client-name"
                              value={getValue(order, "order.first_name")}
                              disabled
                            />
                          </div>
                          <div className="col-6 mb-3">
                            <label>Cognome</label>
                            <input
                              type="text"
                              className="form-control"
                              name="client-surname"
                              id="client-surname"
                              value={getValue(order, "order.last_name")}
                              disabled
                            />
                          </div>
                          <div className="col-12 mb-3">
                            <label>Email</label>
                            <input
                              type="email"
                              className="form-control"
                              name="client-email"
                              id="client-email"
                              value={getValue(order, "order.email")}
                              disabled
                            />
                          </div>
                          <div className="col-12 mb-3">
                            <label>Telefono</label>
                            <input
                              type="tel"
                              className="form-control"
                              name="client-phone"
                              id="client-phone"
                              value={getValue(order, "order.phone")}
                              disabled
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpen && modalCom()}
      {/* {isUpdateSuccess && (
        <ModalComponent onClose={onCloseConfirmationPopUp}>
          <div className="icona-update-status-order">
            <img src={ActiveImg} />
          </div>
          <h2 className="swal2-title mb-0">Grazie!</h2>
          <h2 className="swal2-title">La tua richiesta è stata aggiornata.</h2>
          <div className="btn-update-order">
            <button
              className="btn btn-success"
              onClick={() => onCloseConfirmationPopUp()}
            >
              Chiudi
            </button>
          </div>
        </ModalComponent>
      )} */}
    </>
  );
};
export default EditOrderView;
