import { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import apiService from "../utils/apiService";
import { schema } from "../redux/schema";
import { getValue } from "../utils/commonUtils";
import history from "../history";
const useFetch = (type, options) => {
  let apiSchema = schema[type];
  console.log("apiSchema", apiSchema);
  if (typeof apiSchema === "function") {
    apiSchema = apiSchema({});
  }

  console.log("apiSchema", apiSchema);
  const dispatch = useDispatch();

  const readProps = useSelector(
    (state) => state["applicationReducer"][apiSchema.props],
    shallowEqual
  );

  const readConfig = (dataOptions) => {
    return { ...options, ...dataOptions };
  };

  const readAPISchema = (dataOptions) => {
    const optionConfig = readConfig(dataOptions);
    let apiSchemaFunction = schema[type];
    if (typeof apiSchemaFunction === "function") {
      apiSchemaFunction = apiSchemaFunction(optionConfig);
    }
    return apiSchemaFunction;
  };

  const requestData = (dataOptions) => {
    const parsedSchema = readAPISchema(dataOptions);
    const optionConfig = readConfig(dataOptions);
    if (optionConfig.updateResult || optionConfig.refresh) {
      dispatch({
        type: type + "_PENDING",
        data: getValue(readProps, "readProps.data"),
        readProps: parsedSchema.props,
      });
    } else {
      dispatch({
        type: type + "_PENDING",
        data: optionConfig.initialData,
        readProps: parsedSchema.props,
      });
    }
    if (optionConfig.reset) {
      dispatch({
        type: type + "_RESET",
        data: optionConfig.initialData,
        readProps: parsedSchema.props,
      });
      return;
    }
    console.log("optionConfig", optionConfig);
    console.log("readProps", readProps);
    //API call
    if (localStorage.getItem("userSession")) {
      let timeDiffrence =
        new Date() - new Date(localStorage.getItem("userSession"));
      let hour = Math.floor(timeDiffrence / 1000 / 60 / 60);
      if (hour > 0) {
        dispatch({
          type: "LOG_OUT",
        });
        history.push("/login");
        return;
      }
    }

    apiService({
      url: parsedSchema.url,
      method: parsedSchema.method,
      data: optionConfig.payload,
      headers: parsedSchema.headers,
    })
      .then((response) => {
        if (optionConfig.updateResult) {
          console.log("optionConfig.updateResult", optionConfig.updateResult);
          const updatedResult = optionConfig.updateResult(
            readProps,
            response.data
          );
          console.log("updatedResult", updatedResult);
          if (optionConfig.onSuccess) optionConfig.onSuccess(updatedResult);
          dispatch({
            type: type + "_SUCCESS",
            data: updatedResult,
            readProps: parsedSchema.props,
          });
        } else {
          if (optionConfig.onSuccess) optionConfig.onSuccess(response);
          dispatch({
            type: type + "_SUCCESS",
            data: getValue(response, "response.data.data"),
            readProps: parsedSchema.props,
          });
        }
      })
      .catch((error) => {
        if (optionConfig.onFailure) optionConfig.onFailure(error);

        if (getValue(error, "error.response.status") === 500) {
          dispatch({
            type: "LOG_OUT",
          });
          history.push("/login");
        }

        dispatch({
          type: type + "_FAILURE",
          data: { errorStatus: getValue(error, "error.response.status") },
          readProps: parsedSchema.props,
        });
      });
  };

  const fetchNext = (fetchNextOptions) => {
    requestData(fetchNextOptions);
  };

  const refresh = (refreshOptions) => {
    requestData({ ...refreshOptions, refresh: true });
  };

  useEffect(() => {
    if (options.reset) requestData(options);
  }, [options && options.reset]);

  useEffect(() => {
    if (!options.lazy) {
      requestData(options);
    }
  }, []);

  return [readProps, requestData, { fetchNext, refresh }];
};

export default useFetch;
