import React from "react";
import LoaderComponent from "./../Loader/Loader";

const ResetPasswordView = ({
  onClickResetPassword,
  handleOnChange,
  formData,
  redirectToHomePage,
  loader,
  emailError
}) => {
  return (
    <div>
        {loader && <LoaderComponent />}
      <div className="auth-body-bg">
        <div className="home-btn d-none d-sm-block">
          <a onClick={() => redirectToHomePage()}>
            <i className="mdi mdi-home-variant h2 text-white"></i>
          </a>
        </div>
        <div>
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              <div className="col-lg-4">
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div>
                          <div className="text-center">
                            <div>
                              {/* <a onClick={()=> redirectToHomePage()} className="logo"><img src="./assets/images/Bosch-Logo.png" height="100" alt="logo" /></a> */}
                              <a
                                onClick={() => redirectToHomePage()}
                                className="logo"
                              >
                                <img
                                  src="/images/bosch-power-days.svg"
                                  height="100"
                                  alt="logo"
                                />
                              </a>
                            </div>

                            <h4 className="font-size-18 mt-4">
                              Genera la tua password
                            </h4>
                            <p className="text-muted">
                              Inserisci la tua email utilizzata in fase di registrazione. <br></br>Ti invieremo un'email contenente un link per generare la tua password.
                            </p>
                          </div>

                          <div className="p-2 mt-5">
                            {/* <form
                              className="form-horizontal"
                              action="/"
                            > */}
                            <div className="form-group auth-form-group-custom mb-4">
                              <i className="ri-user-2-line auti-custom-input-icon"></i>
                              <label for="email">Email</label>
                              <input
                                type="text"
                                className="form-control"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={(e) => handleOnChange(e)}
                                placeholder="La tua email"
                              />
                              <span className="error">{emailError}</span>
                            </div>

                            <div className="mt-4 text-center">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                // type="submit"
                                onClick={() => onClickResetPassword()}
                              >
                                Conferma
                              </button>
                            </div>

                            <div className="mt-4 text-center">
                              <a
                                onClick={() =>
                                  redirectToHomePage("/login")
                                }
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock mr-1"></i>Torna alla login
                              </a>
                             </div> 
                            {/* </form> */}
                          </div>

                          <div className="mt-5 text-center">
                            <p>
                              © 2024{" "}
                              <strong>Robert Bosch Power Tools GmbH<br/>P.IVA 00720460153</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPasswordView;
