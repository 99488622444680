import React from "react";
import DashboardImg from "../../assets/images/dashboard.svg";
import OrdiniImg from "../../assets/images/ordini.svg";
import SettingImg from "../../assets/images/setting.svg";

const HeaderView = ({
  onClickLogout,
  onClickPath,
  redirectToHomePage,
  loginData,
}) => {
  return (
    <div>
      <div class="bosch-bar"></div>
      <header id="page-topbar">
        <div class="navbar-header">
          <div class="d-flex">
            <div class="navbar-brand-box">
              <a onClick={() => redirectToHomePage()} class="logo logo-dark">
                <span class="logo-sm">
                  <img src="/images/bosch-power-days.svg" alt="" height="80" />
                </span>
                <span class="logo-lg">
                  <img src="/images/bosch-power-days.svg" alt="" />
                </span>
              </a>
            </div>

            <button
              type="button"
              class="btn btn-sm px-3 font-size-24 d-lg-none header-item"
              data-toggle="collapse"
              data-target="#topnav-menu-content"
            >
              <i class="ri-menu-2-line align-middle"></i>
            </button>
          </div>

          <div class="d-flex">
            <div class="dropdown d-inline-block user-dropdown">
              <button
                type="button"
                class="btn header-item waves-effect"
                id="page-header-user-dropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  className={
                    loginData && loginData.dealer_logo !== "false"
                    ? "rounded-circle header-profile-user foto-dealer"
                    : "rounded-circle header-profile-user"
                  }
                  src={
                    loginData &&
                      loginData.roles &&
                      loginData.roles.includes("administrator")
                      ? "/images/users/avatar-admin.jpg"
                      : loginData.dealer_logo
                  }
                  alt="Header Avatar"
                />
                <span class="d-none d-xl-inline-block ml-1 user-info">
                  <strong>{loginData && loginData.dealer && loginData.dealer.company_name}</strong><br></br>
                  <span class="small-text">{loginData && loginData.dealer && loginData.dealer.city}</span>
                </span>
                {/* <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i> */}
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                <div class="dropdown-divider"></div>
                <a
                  class="dropdown-item text-danger"
                  onClick={(e) => onClickLogout()}
                >
                  <i class="ri-shut-down-line align-middle mr-1 text-danger"></i>{" "}
                  Logout
                </a>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <a
                onClick={(e) => onClickLogout()}
                class="btn w-lg btn-primary btn-no-rounded btn-transition btn-no-border"
              >
                Logout
              </a>
            </div>
          </div>
        </div>
      </header>

      <div class="topnav">
        <div class="container-fluid">
          <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
            <div class="collapse navbar-collapse" id="topnav-menu-content">
              <ul class="navbar-nav">
                {loginData &&
                  loginData.roles &&
                  loginData.roles.includes("administrator") && (
                    <li class="nav-item">
                      <a class="nav-link" onClick={(e) => onClickPath("/")}>
                        <span class="svg-icon">
                          <img src={DashboardImg} />
                        </span>{" "}
                        Dashboard
                      </a>
                    </li>
                  )}

                <li class="nav-item">
                  <a class="nav-link" onClick={(e) => onClickPath("/orders")}>
                    <span class="svg-icon">
                      <img src={OrdiniImg} />
                    </span>{" "}
                    Ordini
                  </a>
                </li>

                {/* <li class="nav-item">
                  <a class="nav-link" onClick={(e) => onClickPath("/")}>
                    <span class="svg-icon">
                      <img src={SettingImg} />
                    </span>{" "}
                    Preferenze utente
                  </a>
                </li> */}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};
export default HeaderView;
