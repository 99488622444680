import React from "react";
import { Provider } from "react-redux";
import ApplicationRouter from "./router/ApplicationRouter";
import { Switch, Router as ReactRouter } from "react-router-dom";
import history from "./history";
import store from "../src/redux/store";
import routes from "./router/routes";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <Provider store={store()}>
        <ReactRouter history={history}>
          <Switch>
            {routes.map((route, index) => {
              return <ApplicationRouter key={index} {...route} />;
            })}
          </Switch>
        </ReactRouter>
      </Provider>
    </div>
  );
}

export default App;
