import React from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderView from "./Header.view";
import history from "../../history";
const HeaderComponent = () => {
  const loginData = useSelector((state) => state.applicationReducer.login);

  const dispatch = useDispatch();
  const onClickLogout = () => {
    dispatch({ type: "LOG_OUT" });
    localStorage.removeItem("user");
    history.push("/login");
  };

  const redirectToHomePage = () => {
    history.push("/");
  };

  const onClickPath = (path) => {
    history.push(path);
  };

  const returnLoginData = (data) => {
    if (data === undefined) {
      return JSON.parse(localStorage.getItem("user"));
    } else {
      return data.data;
    }
  };

  return (
    <HeaderView
      onClickLogout={onClickLogout}
      onClickPath={onClickPath}
      redirectToHomePage={redirectToHomePage}
      loginData={returnLoginData(loginData)}
    />
  );
};
export default HeaderComponent;
