import React, { useEffect } from "react";
import DashboardComponent from "../../components/Dashboard/Dashboard";
import useFetch from "../../hooks/useAPI";
import history from "../../history";
const Dashboard = (props) => {
  const [dashboardData, fetchDashboardData] = useFetch(
    "FETCH_DASHBOARD_DATA",
    {}
  );

  const returnLoginData = () => {
    let data = JSON.parse(localStorage.getItem("user"));
    if (data && data.roles && data.roles.includes("dealer")) {
      history.push("/orders");
    }
  };

  useEffect(() => {
    returnLoginData();
  }, []);

  return (
    <DashboardComponent
      dashboardData={dashboardData && dashboardData.data}
      loader={dashboardData && dashboardData.loading}
    />
  );
};
export default Dashboard;
