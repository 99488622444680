import Dashboard from "../containers/Dashboard";
import Login from "../containers/Login";
import OrderList from "../containers/Order";
import OrderEdit from "../containers/Order/EditOrder";
import ResetPassword from "../containers/ResetPassowrd";
import SetPassword from "../containers/ResetPassowrd/SetPassword";

/**
 * This file contains list of routes, we can also guard/protect routes by passing
 * an attribute isProtected which is false by default.
 *
 * eg. {
 *         path: "/user/profile",
 *         exact: true,
 *         component: UserProfile,
 *         isProtected: true
 *     }
 */
const routes = [
  {
    path: "/",
    exact: true,
    component: Dashboard,
    isProtected: true,
  },
  {
    path: "/login",
    exact: true,
    component: Login,
  },
  {
    path: "/reset-password",
    exact: true,
    component: ResetPassword,
  },
  {
    path: "/set-password",
    exact: true,
    component: SetPassword,
  },
  {
    path: "/orders",
    exact: true,
    component: OrderList,
    isProtected: true,
  },
  {
    path: "/orders/edit/:id",
    exact: true,
    component: OrderEdit,
    isProtected: true,
  },
];

export default routes;
