import React, { useEffect } from "react";
import PropTypes from "prop-types";
import BackImg from "./images/back.svg";
import CloseImg from "./images/close.svg";
import "./style.css";

const ModalComponent = ({
  children,
  onClose,
  onBack,
  isClassAdded,
  type,
  closeOutsideClick = false,
  identifier,
}) => {
  useEffect(() => {
    var orig = document.body.className;
    document.body.className = orig + (orig ? " " : "") + "modal-open";
    return function cleanup() {
      if (document && document.body) {
        document.body.className = document.body.className.replace(
          / ?modal-open/,
          ""
        );
      }
    };
  }, [children]);

  const onCloseClick = (e) => {
    const elementId = e.target.id;
    if (
      elementId === "modal_" + identifier ||
      elementId === "close_" + identifier
    ) {
      e.stopPropagation();
      onClose();
    } else if (elementId === "back_" + identifier) {
      e.stopPropagation();
      onBack();
    }
  };

  const modelElementId = "modal_" + identifier;
  const closeElementId = "close_" + identifier;
  const backElementId = "back_" + identifier;
  return (
    <>
      <div
        className={`modal ihub-modal ${isClassAdded ? isClassAdded : null}`}
        tabindex="-1"
        role="dialog"
        onClick={
          closeOutsideClick
            ? onCloseClick
            : (e) => {
                // e.stopPropagation();
              }
        }
        id={modelElementId}
      >
        <div className={"modal-dialog modal-dialog-centered"} role="document">
          <div className="modal-content">
            <div className="modal-header">
              {onBack && (
                <button
                  type="button"
                  className="close back-btn"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeOutsideClick ? onCloseClick : onBack}
                  id={backElementId}
                >
                  <img src={BackImg} alt="" id={backElementId} />
                </button>
              )}
              {onClose && (
                <button
                  type="button"
                  className="close close-btn"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeOutsideClick ? onCloseClick : onClose}
                  id={closeElementId}
                >
                  <img src={CloseImg} alt="" id={closeElementId} />
                </button>
              )}
            </div>

            <div className="modal-body">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};
ModalComponent.contextTypes = {
  onClose: PropTypes.func,
  onBack: PropTypes.func,
};

export default ModalComponent;
