import React from "react";
import LoaderComponent from "./../Loader/Loader";

const SetPasswordView = ({
  onClickSetPassword,
  formData,
  handleOnChange,
  message,
  redirectToHomePage,
  loader,
  passwordError,
  errorData,
}) => {
  return (
    <div>
      {loader && <LoaderComponent />}
      <div className="auth-body-bg">
        <div className="home-btn d-none d-sm-block">
          <a onClick={() => redirectToHomePage()}>
            <i className="mdi mdi-home-variant h2 text-white"></i>
          </a>
        </div>
        <div>
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              <div className="col-lg-4">
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div>
                          <div className="text-center">
                            <div>
                              {/* <a onClick={()=> redirectToHomePage()} className="logo"><img src="./assets/images/Bosch-Logo.png" height="100" alt="logo" /></a> */}
                              <a
                                onClick={() => redirectToHomePage()}
                                className="logo"
                              >
                                <img
                                  src="/images/bosch-power-days.svg"
                                  height="100"
                                  alt="logo"
                                />
                              </a>
                            </div>

                            <h4 className="font-size-18 mt-4">
                              Scegli una nuova password
                            </h4>
                            <p className="text-muted">
                              Inserisci il tuo indirizzo email e <br></br>crea la tua nuova password.
                            </p>
                          </div>

                          <div className="display-message">
                            <span>{message}</span>
                          </div>

                          <div className="p-2 mt-5">
                            {/* <form
                              className="form-horizontal"
                              action="/"
                            > */}
                            <div className="form-group auth-form-group-custom mb-4">
                              <i className="ri-lock-2-line auti-custom-input-icon"></i>
                              <label for="userpassword">Email</label>
                              <input
                                type="email"
                                value={formData.email}
                                name="email"
                                onChange={(e) => handleOnChange(e)}
                                className="form-control"
                                placeholder="La tua email"
                              />
                              <span class="error">{errorData.email}</span>
                            </div>
                            <div className="form-group auth-form-group-custom mb-4">
                              <i className="ri-lock-2-line auti-custom-input-icon"></i>
                              <label for="userpassword">Password</label>
                              <input
                                type="password"
                                value={formData.password}
                                name="password"
                                onChange={(e) => handleOnChange(e)}
                                className="form-control"
                                id="userpassword"
                                placeholder="La tua password"
                              />
                              <span class="error">{errorData.password}</span>
                            </div>
                            <div className="form-group auth-form-group-custom mb-4">
                              <i className="ri-lock-2-line auti-custom-input-icon"></i>
                              <label for="userpassword">Conferma password</label>
                              <input
                                type="password"
                                value={formData.confirmpassword}
                                name="confirmpassword"
                                onChange={(e) => handleOnChange(e)}
                                className="form-control"
                                id="confirmuserpassword"
                                placeholder="Conferma la tua password"
                              />
                              <span class="error">{errorData.confirmpassword}</span>
                            </div>

                            <div className="mt-4 text-center">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                // type="submit"
                                onClick={() => onClickSetPassword()}
                              >
                                Conferma
                              </button>
                            </div>

                            {/* </form> */}
                          </div>

                          <div className="mt-5 text-center">
                            <p>
                              © 2024{" "}
                              <strong>Robert Bosch Power Tools GmbH<br/>P.IVA 00720460153</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SetPasswordView;
