import React, { useEffect } from "react";
import ResetPasswordComponent from "../../components/ResetPassword/ResetPassword";
import useFetch from "../../hooks/useAPI";
import history from "../../history";
const ResetPassword = (props) => {
  const [resetPassword, callResetPassword] = useFetch("RESET_USER_PASSWORD", {
    lazy: true,
  });
  useEffect(() => {
    if (resetPassword && resetPassword.data) {
      history.push("/login?message=reset");
    }
  }, [resetPassword]);
  const onSubmit = (payload) => {
    callResetPassword({ payload });
  };
  return <ResetPasswordComponent onSubmit={onSubmit} />;
};
export default ResetPassword;
