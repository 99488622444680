import React, { useEffect, useState } from "react";
import useFetch from "../../hooks/useAPI";
import OrderListComponent from "../../components/Order/OrderList";
import { getValue } from "../../utils/commonUtils";
const OrderList = (props) => {
  const [statusData, setStatusData] = useState("wc-all");
  const [reset, setReset] = useState(false);
  const [status, getStatus] = useFetch("FETCH_ORDER_CATEGORY_LIST", {});

  const [orders, getOrders] = useFetch("FETCH_ORDER_LIST", {
    lazy: true,
  });

  const [ordersCsv, fetchOrdersCsv] = useFetch("FETCH_ORDERS_CSV", {
    lazy: true,
    //reset,
  });

  const [productCsv, fetchProductsCsv] = useFetch("FETCH_PRODUCTS_CSV", {
    lazy: true,
   // reset,
  });

  const [adminOrderCsv, fetchAdminOrderCsv] = useFetch("FETCH_ADMIN_ORDER_CSV", {
    lazy: true,
   // reset,
  });

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (getValue(orders, "orders.data.order")) setLoader(false);
  }, [getValue(orders, "orders.data.order")]);

  useEffect(() => {
    if (ordersCsv && ordersCsv.data && ordersCsv.data.file_url) {
      downloadFile(ordersCsv.data.file_url);
    }
  }, [ordersCsv]);
  useEffect(() => {
    if (productCsv && productCsv.data && productCsv.data.file_url) {
      downloadFile(productCsv.data.file_url);
    }
  }, [productCsv]);

  useEffect(() => {
    if (adminOrderCsv && adminOrderCsv.data && adminOrderCsv.data.file_url) {
      downloadFile(adminOrderCsv.data.file_url);
    }
  }, [adminOrderCsv]);

  

  const downloadFile = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setReset(true);
  };

  useEffect(() => {
    fetchOrders({ status: statusData });
    return () => {
      setReset(false);
    };
  }, []);

  const fetchOrders = (data) => {
    setLoader(true);
    setStatusData(data.status);
    getOrders({ ...data });
  };

  return (
    <OrderListComponent
      status={status}
      orders={orders}
      statusData={statusData}
      fetchOrders={fetchOrders}
      loader={loader}
      fetchOrdersCsv={fetchOrdersCsv}
      fetchProductsCsv={fetchProductsCsv}
      fetchAdminOrderCsv={fetchAdminOrderCsv}
    />
  );
};
export default OrderList;
