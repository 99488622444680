import React, { useEffect, useState } from "react";
import useFetch from "../../hooks/useAPI";
import { getValue } from "../../utils/commonUtils";
import EditOrderComponent from "../../components/Order/EditOrder";
const EditOrder = (props) => {
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);

  const [order, getOrder] = useFetch("FETCH_ORDER_DETAIL", {
    lazy: true,
  });

  const onSuccess = (data) => {
    setIsUpdateSuccess(true);
  };

  const onFailure = (data) => {
    console.log("onFailure", data);
  };

  const onCloseConfirmationPopUp = () => {
    setIsUpdateSuccess(!isUpdateSuccess);
  };

  const [updateOrderData, updateOrder] = useFetch("UPDATE_ORDER", {
    lazy: true,
    onSuccess,
    onFailure,
  });

  const onApiCall = (payload) => {
    updateOrder({
      id: order.data.id,
      payload: { status: payload.status, note: payload.note },
    });
  };

  useEffect(() => {
    getOrder({ id: getValue(props, "props.computedMatch.params.id") });
  }, [getValue(props, "props.computedMatch.params.id")]);

  return (
    <>
      <EditOrderComponent
        order={order}
        onApiCall={onApiCall}
        updateOrderData={updateOrderData}
        isUpdateSuccess={isUpdateSuccess}
        onCloseConfirmationPopUp={onCloseConfirmationPopUp}
      />
    </>
  );
};
export default EditOrder;
