import { combineReducers } from "redux";
import { applicationReducer } from "./applicationReducer";

const appReducer = combineReducers({ applicationReducer });

const rootReducer = (state, action) => {
  if (action.type === "LOG_OUT") {
    localStorage.removeItem("user");
    localStorage.removeItem("userSession");
    state = {};
  }
  return appReducer(state, action);
};

export default rootReducer;
