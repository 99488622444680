import React, { useState, useEffect } from "react";
//import leftArrowImg from "../../assets/images/arrow-left-blue.svg";
// import rightArrowImg from "../../assets/images/right-arrow-blck.svg";
// import Pagination from "react-js-pagination";
import Pagination from "react-pagination-js";


import "react-pagination-js/dist/styles.css"; // import css


const PaginationComponent = ({ data, onClickApi }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  useEffect(() => {
    if (data.page) {
      setCurrentPage(data.page);
    }
    if (data.total_page) {
      setLastPage(data.total_page);
    }
  }, [data]);

  const onClickNext = () => {
    let page = currentPage;
    page += 1;
    if (page < lastPage || page === lastPage) {
      setCurrentPage(page);
      onClickApi(page);
    }
  };
  const onClickPrev = () => {
    let page = currentPage;
    page -= 1;
    if (page !== 0) {
      setCurrentPage(page);
      onClickApi(page);
    }
  };

  const onCLickPage = (page) => {
    setCurrentPage(page);
    onClickApi(page);
  };

  const onCLickPageNew = (page) => {
    console.log(page)
    setCurrentPage(page);
    onClickApi(page);
  }

  const renderNumber = () => {
    let data = [];
    for (let i = 1; i <= lastPage; i++) {
      data.push(
        <div
          className="d-inline-block bold blue font-20 padding-left-30 pointer numbers"
          onClick={(e) => onCLickPage(i)}
        >
          {i}
        </div>
      );
    }
    return data;
  };
  console.log("data",data)
  return (
    <div className="pagination-order margin-top-20">
      <Pagination
        currentPage={data.page}
        // totalPages={data.total_page} 
        sizePerPage={data.size}
        changeCurrentPage={onCLickPageNew}
        numberOfPagesNextToActivePage={5}
        totalSize={data.total_order}
        nextPageText={"Prossima"}
        previousPageText={"Precedente"}
        showFirstLastPages={false}
      />
    </div>

  );
};
export default PaginationComponent;
